import * as yup from 'yup';

export const coachFormSchema = yup.object().shape({
  campus_id: yup.string().required('School is required'),
  city: yup.string().required('City is required'),
  country: yup.string().required('Country is required'),
  date_of_birth: yup
    .string()
    .required('Date of birth is required')
    .matches(
      /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d{2}$/,
      'Date must be in format: MM/DD/YYYY',
    ),
  email: yup
    .string()
    .email('Invalid email')
    .required('Contact Email is required'),
  mailing_address: yup.string().required('Mailing address is required'),
  name: yup.string().required('Name is required'),
  native_language: yup.string().required('Native language is required'),
  phone_number: yup
    .string()
    .required('Phone is required')
    .matches(
      /^\+1 \(\d{3}\) \d{3}-\d{4}$/,
      'Phone number must be in format: +1 (999) 999-9999',
    ),
  profile_img: yup.string(),
  state: yup.string().required('State is required'),
  timezone: yup.string().required('Timezone is required'),
});
