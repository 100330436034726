import ChooseCoach from '../../features/coach-matching/components/choose-coach/choose-coach';
import ChooseTimeslot from '../../features/coach-matching/components/choose-timeslot/choose-timeslot';
import Congratulations from '../../features/coach-matching/components/congratulations/congratulations';

export const stepKeys: { [key: string]: string } = {
  chooseCoach: 'chooseCoach',
  chooseTimeslot: 'chooseTimeslot',
  congratulations: 'congratulations',
};

export const stepTitles: { [key: string]: string } = {
  chooseCoach: 'Choose your coach',
  chooseTimeslot: 'Choose a timeslot',
  congratulations: 'Congratulations!',
};

export const stepSubtitles: { [key: string]: string } = {
  chooseCoach: 'Select a coach to work with',
  congratulations: 'First session scheduled.',
};

export const stepValidationErrors: { [key: string]: string } = {
  chooseCoach: 'Please choose a coach to continue',
  chooseTimeslot: 'Please choose a timeslot to continue',
};

export const buttons: { [key: string]: string } = {
  back: '< Back',
  chooseCoach: 'Choose coach',
  exploreMore: 'Explore more',
  continue: 'Continue',
  goToApp: 'Go to app',
};

export const coachMatchingSteps: any = {
  chooseCoach: {
    button: buttons.chooseCoach,
    component: ChooseCoach,
    key: stepKeys.chooseCoach,
    nextStepKey: stepKeys.chooseTimeslot,
    objectValidation: true,
    subtitle: stepSubtitles[stepKeys.chooseCoach],
    title: stepTitles[stepKeys.chooseCoach],
  },
  chooseTimeslot: {
    button: buttons.continue,
    component: ChooseTimeslot,
    key: stepKeys.chooseTimeslot,
    nextStepKey: stepKeys.congratulations,
    objectValidation: true,
    prevStepKey: stepKeys.chooseCoach,
    subtitle: stepSubtitles[stepKeys.chooseTimeslot],
    title: stepTitles[stepKeys.chooseTimeslot],
  },
  congratulations: {
    button: buttons.goToApp,
    className: 'centered',
    component: Congratulations,
    key: stepKeys.congratulations,
    subtitle: stepSubtitles[stepKeys.congratulations],
    title: stepTitles[stepKeys.congratulations],
  },
};
