import React, { FC } from 'react';
import { useController, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import {
  Button,
  Divider,
  Flex,
  Form,
  Typography,
} from '../../../../../../common';
import { CreateFormProps } from './create-form.types';
import { InputV2 } from '../../../../../register-coachee/components/ui/Input-v2';
import { SelectV2 } from '../../../../../register-coachee/components/ui/select-v2';
import {
  countries,
  states,
} from '../../../../../coach/utils/constants/USStates';
import { campusFormSchema } from './create-form.schema';
import axiosInstance from '../../../../../../utils/helpers/http';

export const CreateCampusForm: FC<CreateFormProps> = props => {
  const { onHideDrawer, onRefresh } = props;

  const { control, formState, handleSubmit, register } = useForm({
    defaultValues: {
      city: '',
      contactEmail: '',
      contactFullName: '',
      country: countries[0].value,
      mailingAddress: '',
      name: '',
      state: '',
    },
    resolver: yupResolver(campusFormSchema),
  });

  const {
    field: { onChange: onStateChange, value: stateValue, ...restStateField },
  } = useController({ control, name: 'state' });

  const {
    field: {
      onChange: onCountryChange,
      value: countryValue,
      ...restCountryField
    },
  } = useController({ control, name: 'country' });

  const { errors } = formState;

  // @ts-ignore
  const submit = async data => {
    try {
      await axiosInstance.post('admin/campuses', data);
      toast.success('Campus created successfully');
      onRefresh();
      onHideDrawer();
    } catch {
      toast.error('An error occurred while creating campus');
    }
  };

  return (
    <Form
      Footer={
        <React.Fragment>
          <Button
            iconPosition="right"
            text="Discard"
            variant="action"
            onClick={onHideDrawer}
          />
          <Button iconPosition="right" text="Add Campus" type="submit" />
        </React.Fragment>
      }
      Header={<Typography text="Add Campus" variant="title3" />}
      onClose={onHideDrawer}
      onSubmit={handleSubmit(submit)}
    >
      <Typography
        marginBottom="x"
        text="General"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="2x" marginTop="4x">
        <InputV2
          errorMessage={errors.name?.message}
          label="Campus Name"
          placeholder="Enter name"
          {...register('name')}
        />

        <InputV2
          errorMessage={errors?.city?.message}
          label="City"
          placeholder="Enter city"
          {...register('city')}
        />

        <SelectV2
          errorMessage={errors.state?.message}
          label="State"
          options={states}
          placeholder="Choose state"
          value={
            stateValue
              ? states.find(state => {
                  return state.value === stateValue;
                })
              : null
          }
          onChange={option => {
            return onStateChange(option ? option.value : option);
          }}
          {...restStateField}
        />

        <SelectV2
          errorMessage={errors.country?.message}
          label="Country"
          options={countries}
          placeholder="Choose country"
          value={
            countryValue
              ? countries.find(country => {
                  return country.value === countryValue;
                })
              : null
          }
          onChange={option => {
            return onCountryChange(option ? option.value : option);
          }}
          {...restCountryField}
        />
        <InputV2
          errorMessage={errors.mailingAddress?.message}
          label="Mailing Address"
          placeholder="Enter mailing address"
          {...register('mailingAddress')}
        />
      </Flex>

      <Typography
        marginTop="4x"
        text="Point of Contact"
        textStyle="bold"
        variant="subtitle2"
      />
      <Divider color="gray-200" height="1px" width="100%" />

      <Flex direction="column" gap="2x" marginBottom="8x" marginTop="4x">
        <InputV2
          errorMessage={errors.contactFullName?.message}
          label="Full Name"
          placeholder="Enter full name"
          {...register('contactFullName')}
        />
        <InputV2
          errorMessage={errors.contactEmail?.message}
          label="Email"
          placeholder="email@domain.com"
          {...register('contactEmail')}
        />
      </Flex>
    </Form>
  );
};
