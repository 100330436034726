import { Fragment, useEffect } from 'react';
import {
  ExtendableListing,
  Listing,
} from '../../../../utils/interfaces/RegisterStudent';
import CustomEntry from '../ui/CustomEntry/CustomEntry';
import SelectableCard from '../ui/SelectableCard/SelectableCard';
import { registerStudentSteps } from '../../utils/constants/RegisterStudent';
import {
  handleScreenDataChange,
  removeCustomCoachPreference,
} from '../../store/registerStudentSlice';
import { useAppDispatch } from '../../../../store/hooks';

import styles from './CoachPreferences.module.scss';
import modalStyles from '../ui/Modal/Modal.module.scss';

type Props = {
  data: { [key: string]: boolean };
  isRegister?: boolean;
  listing: ExtendableListing;
  onChangeHandler?: (data: any) => void;
};

const CoachPreferences = ({
  data,
  isRegister,
  listing,
  onChangeHandler,
}: Props) => {
  const dispatch = useAppDispatch();
  let coachPreferencesSelection: { [key: string]: boolean } = { ...data };
  const handleSelect = (value: string, isSelected: boolean) => {
    if (isSelected) {
      coachPreferencesSelection = {
        ...coachPreferencesSelection,
        [value]: true,
      };
    } else {
      delete coachPreferencesSelection[value];
    }

    if (isRegister) {
      dispatch(
        handleScreenDataChange({ coachPreferences: coachPreferencesSelection }),
      );
    } else {
      onChangeHandler!({
        preferences: coachPreferencesSelection,
      });
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container} data-testid="coachPreferrence-container">
      {listing.predefined.map((preference: Listing) => {
        return (
          <SelectableCard
            key={preference.value}
            label={preference.label}
            selected={data[preference.value]}
            value={preference.value}
            onSelect={handleSelect}
          />
        );
      })}
      {listing.custom!.length !== 0 && (
        <Fragment>
          <div className={modalStyles.customEntries}>
            {registerStudentSteps.coachPreferences.customLabel}
          </div>
          {listing.custom!.map((preference: Listing, index: number) => {
            return (
              <CustomEntry
                inWizard
                key={preference.label + index}
                label={preference.label}
                removeSelection={() => {
                  return dispatch(
                    removeCustomCoachPreference(preference.value),
                  );
                }}
              />
            );
          })}
        </Fragment>
      )}
    </div>
  );
};

export default CoachPreferences;
