import React, { useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import {
  DateSelectArg,
  DayHeaderContentArg,
  EventClickArg,
  EventInput,
} from '@fullcalendar/core';
import { v4 as uuidV4 } from 'uuid';
import { convertEventsToTimeslotsInUtc } from './coaching-timeslots-days-list/coaching-timeslots-day.helpers';
import { add30Minutes, firstDateOfWeek } from '../../../../utils/helpers/date';
import { CoachingTimeslot } from '../../interfaces/CoachingTimeslot';
import { updateAvailableTimeslot } from '../../api/updateAvailableTimeslots';
import { useCoachAvailableTimeslots } from '../../api/useCoachAvailableTimeslots';
import UserLayout from '../../../general/components/user-layout/user-layout';
import { CalendarWrapper, Conditional, Flex } from '../../../../common';
import Button from '../../../register-coachee/components/ui/Button/Button';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';

const CoachingTimeslots = () => {
  const coach = localStorageUser();
  const [events, setEvents] = useState<EventInput[]>([]);
  const {
    from,
    loadTimeslotsFromPreviousWeekWithTimeslots,
    onDatesSet,
    status,
    timeslots,
    to,
  } = useCoachAvailableTimeslots();
  const calendarRef = useRef<FullCalendar>(null);

  useEffect(() => {
    if (status === 'LOADED') {
      setEvents(
        timeslots?.map((timeslot: CoachingTimeslot) => {
          return {
            color: timeslot.student ? '#ec6359' : '#73be76',
            editable: false,
            end: new Date(+timeslot.end_time),
            extendedProps: {
              ...timeslot,
            },
            groupId: uuidV4(),
            id: timeslot.id || uuidV4(),
            start: new Date(+timeslot.start_time),
            title: timeslot.student?.name
              ? `Apt. ${timeslot.student?.name}`
              : 'Available',
          } as EventInput;
        }),
      );
    }
  }, [status, timeslots]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const saveSelection = async (updatedEvents: EventInput[]) => {
    try {
      await updateAvailableTimeslot(
        coach.id,
        from,
        to,
        convertEventsToTimeslotsInUtc(updatedEvents),
      );

      setEvents(updatedEvents => {
        return updatedEvents.map(event => {
          return {
            ...event,
            pending: false,
          };
        }) as EventInput[];
      });

      toast.success('Timeslots updated successfully');
    } catch {
      toast.error('Error updating timeslots, please try again');
    }
  };

  const handleSelect = async (arg: DateSelectArg) => {
    const startDate = new Date(arg.start);

    const updatedEvents = [...events];

    while (startDate < arg.end) {
      let endDate = add30Minutes(startDate.getTime());

      if (endDate > arg.end.getTime()) {
        endDate = arg.end.getTime();
      }

      const newEvent: EventInput = {
        editable: false,
        end: new Date(endDate),
        groupId: uuidV4(),
        id: uuidV4(),
        pending: true,
        start: new Date(startDate),
        title: 'Available',
      };

      updatedEvents.push(newEvent);

      // Increment the start time by the duration
      startDate.setMinutes(startDate.getMinutes() + 30);
    }

    setEvents(updatedEvents);

    await saveSelection(updatedEvents);
  };

  function renderHeader(arg: DayHeaderContentArg) {
    const dayName = arg.date.toLocaleDateString('en-US', { weekday: 'long' });
    return dayName.charAt(0);
  }

  async function handleEventClick(arg: EventClickArg) {
    const eventIndex = events.findIndex(event => {
      return event.id === arg.event.id;
    });

    if (eventIndex === -1) {
      return;
    }

    const event = events[eventIndex];

    if (!event.extendedProps?.student) {
      const newEvents = [...events];
      newEvents.splice(eventIndex, 1);

      setEvents(newEvents);
      await saveSelection(newEvents);
    }
  }

  return (
    <UserLayout centeredTitle title="Coaching Timeslots">
      <CalendarWrapper>
        <FullCalendar
          allDaySlot={false}
          datesSet={onDatesSet}
          dayHeaderContent={renderHeader}
          displayEventTime={false}
          eventClick={handleEventClick}
          eventOverlap={false}
          events={events}
          headerToolbar={{
            left: 'title',
            right: 'prev,next',
          }}
          height="calc(100vh - 180px)"
          initialView="timeGridWeek"
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          ref={calendarRef}
          select={handleSelect}
          selectable
          selectOverlap={false}
          unselectAuto={false}
          validRange={{ start: firstDateOfWeek() }}
        />
      </CalendarWrapper>

      <Conditional condition={status === 'LOADED' && !events?.length}>
        <Flex justifyContent="center" marginTop="2x">
          <Button
            label="Load last timeslots"
            onClickHandler={() => {
              return loadTimeslotsFromPreviousWeekWithTimeslots();
            }}
          />
        </Flex>
      </Conditional>
    </UserLayout>
  );
};

export default CoachingTimeslots;
