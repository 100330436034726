import moment from 'moment';
import { DateInput } from '@fullcalendar/core';

export type DateFormats =
  | 'MMM D, YYYY'
  | 'MMM DD, YYYY'
  | 'MM/DD/YYYY'
  | 'YYYY-MM-DD'
  | 'MMM D, YYYY HH:mm:ss'
  | 'dddd - MM/DD/YYYY'
  | 'HH:mm:ss'
  | 'hh:mm a'
  | 'dddd';

export const formatDate = (
  date: Date | DateInput,
  format: DateFormats = 'MMM D, YYYY',
) => {
  return moment(date).format(format);
};

export const firstDateOfWeek = () => {
  return moment().startOf('week').toDate();
};

export const lastDateOfWeek = () => {
  return moment().endOf('week').toDate();
};

export const addMinutes = (date: Date, minutes: number) => {
  return moment(date).add(minutes, 'minutes').toDate();
};

export const addToDate = (
  date: Date,
  amount: number,
  unit: moment.unitOfTime.DurationConstructor,
) => {
  return moment(date).add(amount, unit).toDate();
};

export const addToCurrentDate = (
  amount: number,
  unit: moment.unitOfTime.DurationConstructor,
) => {
  return addToDate(new Date(), amount, unit);
};

export const convertTimeToUtc = (time: string, timezone: string) => {
  const localTime = moment.tz(time, 'HH:mm:ss', timezone);
  return localTime.utc().format('HH:mm:ss');
};

export const convertLocalDateToUtc = (date: string | Date | DateInput) => {
  const localTime = moment(date);
  return localTime.utc().toString();
};

export const add30Minutes = (date: number): number => {
  return date + 1800000;
};

export const parseDate = (dateStr: string) => {
  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(year, month - 1, day);
};

export const convertDateToUtcMilliseconds = (
  date: string | Date | DateInput,
  timezone: string,
) => {
  return moment.tz(date, timezone).get('milliseconds');
};

export const convertUtcToLocal = (
  time: string,
  timezone: string,
  format: DateFormats = 'MMM D, YYYY',
) => {
  const utcTime = moment.tz(time, format, 'UTC');
  return utcTime.tz(timezone).format(format);
};

export const convertUtcToLocalDate = (
  time: string | Date | DateInput,
  timezone: string,
) => {
  const utcTime = moment.tz(time, 'UTC');
  return utcTime.tz(timezone).toDate();
};

export const convertTimeInMillisecondsToLocalDate = (time: number | string) => {
  return new Date(+time);
};

export const convertDateAndTimeToMilliseconds = (
  date: Date,
  time: string,
): number => {
  const [hours, minutes, seconds] = time.split(':').map(Number);

  if (
    hours < 0 ||
    hours > 23 ||
    minutes < 0 ||
    minutes > 59 ||
    seconds < 0 ||
    seconds > 59
  ) {
    throw new Error('Invalid time format');
  }

  const newDate = new Date(date.getTime());
  newDate.setHours(hours, minutes, seconds);

  return newDate.getTime();
};

export const isDateInFuture = (date: number) => {
  return date > Date.now();
};

export const areTimesEqual = (
  timestamp1: number,
  timestamp2: number,
): boolean => {
  const date1 = new Date(timestamp1);
  const date2 = new Date(timestamp2);

  const hours1 = date1.getUTCHours();
  const minutes1 = date1.getUTCMinutes();
  const seconds1 = date1.getUTCSeconds();

  const hours2 = date2.getUTCHours();
  const minutes2 = date2.getUTCMinutes();
  const seconds2 = date2.getUTCSeconds();

  return hours1 === hours2 && minutes1 === minutes2 && seconds1 === seconds2;
};

export const getLocalTimezone = () => {
  const timezone = moment.tz.guess();
  const abbreviation = moment([2000, 1]).tz(timezone).format('z');
  return `${timezone} (${abbreviation})`;
};
