import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { Amplify } from "aws-amplify";
import { store } from "./store/store";
import TrulaCampusApp from "./App";

import reportWebVitals from "./reportWebVitals";

import "./index.scss";

// To re-use an existing authentication resource from AWS
Amplify.configure({
  Auth: {
    // Amazon Cognito Region
    region: process.env.REACT_APP_AWS_REGION,
    
    // This is used when autoSignIn is enabled for Auth.signUp
// 'code' is used for Auth.confirmSignUp, 'link' is used for email link verification
signUpVerificationMethod: "code",

// Amazon Cognito User Pool ID
userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
    
    // Amazon Cognito Web Client ID (26-char alphanumeric string)
userPoolWebClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID, // 'code' | 'link'
  },
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <TrulaCampusApp />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();