import { Fragment, ReactNode, useEffect, useRef, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { STATUSES } from '../../../register-coachee/utils/constants/Statuses';
import menu from '../../../../assets/icons/common/menu.svg';
import conversation from '../../../../assets/icons/CoachDashboard/conversation.svg';
import { ArrowBack } from '../../../../assets/icons/common/Common';
// @ts-ignore
import { changeCoach } from '../../../coachee/api/changeCoach';
import { updateStudent } from '../../../register-coachee/api/updateStudent';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import useAuth from '../../../authentication/hooks/UseAuth';
import EmptyModal from '../empty-modal/empty-modal';
import Button from '../../../register-coachee/components/ui/Button/Button';
import { setOverlay } from '../../store/generalSlice';
import { addModalToPortal } from '../../utils/helpers/addModalToPortal';

import styles from './user-layout.module.scss';

type Props = {
  centeredTitle?: boolean;
  children: ReactNode;
  customBackAction?: () => void;
  hideBackElement?: boolean;
  isConversationTab?: boolean;
  title?: string;
};

const UserLayout = ({
  centeredTitle,
  children,
  customBackAction,
  hideBackElement,
  isConversationTab,
  title,
}: Props) => {
  const ref = useRef(null);
  const { cognitoUserSignOut } = useAuth();
  const cognitoGroup = localStorage.getItem('cognitoGroup')!;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showDropdown, setShowDropdown] = useState(false);
  const [showChangeCoachModal, setShowChangeCoachModal] = useState(false);
  const [changeCoachReason, setChangeCoachReason] = useState('');
  const { studentId } = useParams();

  const startConversationIcon = (
    <Link to={`/coach/conversations/${studentId}/new`}>
      <div className={styles.conversationIcon}>
        <img alt="Start conversation" src={conversation} />
      </div>
    </Link>
  );

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowDropdown(false);
        }
      }

      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  };
  useOutsideAlerter(ref);

  const navigateToProfile = () => {
    switch (cognitoGroup) {
      case 'coach':
        navigate('/coach/profile');
        break;
      case 'coachee':
        navigate('/coachee/profile');
        break;
      default:
        navigate('/login');
        break;
    }
  };

  const handleChangeCoach = async () => {
    try {
      await changeCoach(changeCoachReason);
      setChangeCoachReason('');
      dispatch(setOverlay(false));
      setShowChangeCoachModal(false);

      const userResponse = await updateStudent({
        status: STATUSES.CHANGE_COACH,
      });
      localStorage.setItem('user', JSON.stringify(userResponse?.data));
      navigate('/coachee/coach-matching');
    } catch (error) {
      console.error(error);
    }
  };

  const changeCoachReasonRender = (
    <div className={styles.modalContainer}>
      <textarea
        className={styles.textarea}
        name="note"
        rows={12}
        onChange={e => {
          return setChangeCoachReason(e.target.value);
        }}
      />
      <div className={styles.modalButtonContainer}>
        <Button
          className="topShift"
          isDisabled={false}
          label="Continue"
          onClickHandler={() => {
            handleChangeCoach();
            dispatch(setOverlay(false));
          }}
        />
        <div
          className={styles.skipButton}
          onClick={() => {
            setShowChangeCoachModal(false);
            dispatch(setOverlay(false));
          }}
        >
          Cancel
        </div>
      </div>
    </div>
  );

  const changeCoachModal = (
    <EmptyModal
      cssClass={styles.modal}
      handleClose={() => {
        setShowChangeCoachModal(false);
        dispatch(setOverlay(false));
      }}
      subtitle="Please share the reason for changing your coach."
      title="Change your coach"
    >
      {changeCoachReasonRender}
    </EmptyModal>
  );

  const menuElement = (
    <Fragment>
      <div className={styles.topRightActions}>
        {isConversationTab ? startConversationIcon : ''}{' '}
        <img
          alt="Menu"
          className={styles.menuIcon}
          src={menu}
          onClick={() => {
            setShowDropdown(!showDropdown);
          }}
        />
      </div>
      {showDropdown && (
        <div className={styles.dropdown} ref={ref}>
          <span
            className={styles.dropdownItem}
            onClick={() => {
              navigateToProfile();
              setShowDropdown(false);
            }}
          >
            Profile
          </span>
          {!!cognitoGroup && cognitoGroup === 'coach' ? (
            <Link
              className={styles.dropdownItem}
              to="/coach/coaching-timeslots"
            >
              Coaching Timeslots
            </Link>
          ) : (
            ''
          )}
          {!!cognitoGroup &&
          cognitoGroup === 'coachee' &&
          localStorageUser().status === STATUSES.ACTIVE ? (
            <span
              className={styles.dropdownItem}
              onClick={() => {
                setShowChangeCoachModal(true);
                dispatch(setOverlay(true));
                setShowDropdown(false);
              }}
            >
              Change Coach
            </span>
          ) : (
            ''
          )}
          <span
            className={styles.dropdownItem}
            onClick={() => {
              return cognitoUserSignOut();
            }}
          >
            Logout
          </span>
        </div>
      )}
    </Fragment>
  );

  const titleElement = (
    <div
      className={`${styles.title} ${
        centeredTitle ? styles.center : styles.verifyTitle
      }`}
      data-testid="layout-title"
    >
      {title}
    </div>
  );

  const backElement = (
    <div
      className={`${styles.mt10} ${styles.backButton}`}
      onClick={() => {
        if (customBackAction) {
          customBackAction();
        } else {
          navigate(-1);
        }
      }}
    >
      <ArrowBack />
    </div>
  );

  const emptyBackElement = (
    <div className={`${styles.mt10} ${styles.backButton}`} />
  );

  const header = (
    <div className={styles.header}>
      {!hideBackElement ? backElement : centeredTitle ? emptyBackElement : ''}
      {titleElement}
      {menuElement}
    </div>
  );

  return (
    <div className={styles.container}>
      {header}
      {!!showChangeCoachModal && addModalToPortal(changeCoachModal)}
      <div className={styles.content}>{children}</div>
    </div>
  );
};

export default UserLayout;
