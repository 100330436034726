import React, { useEffect, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate, useParams } from 'react-router-dom';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { DateSelectArg, EventDropArg, EventInput } from '@fullcalendar/core';
import { v4 as uuidV4 } from 'uuid';
import { useImmer } from 'use-immer';
import { addWeeks } from '@fullcalendar/core/internal';
import EmptyModal from '../../../general/components/empty-modal/empty-modal';
import UserLayout from '../../../general/components/user-layout/user-layout';
import UserInfo from '../user-info/user-info';
import Button from '../../../register-coachee/components/ui/Button/Button';
import { useCoachTimeslots } from '../../../coach/api/useCoachTimeslots';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { rescheduleSession } from '../../../general/api/rescheduleSession';
import { useSesssionDetailsFetch } from '../../../coach/api/useSessionDetailsFetch';
import { STATUSES } from '../../../register-coachee/utils/constants/Statuses';
import { useAppDispatch } from '../../../../store/hooks';
import { setOverlay } from '../../../general/store/generalSlice';
import { addModalToPortal } from '../../../general/utils/helpers/addModalToPortal';
import { CalendarWrapper, Conditional, Typography } from '../../../../common';
import {
  add30Minutes,
  addToDate,
  firstDateOfWeek,
  formatDate,
} from '../../../../utils/helpers/date';
import { findOverlapBackgroundEvent } from '../../../coach/components/coaching-timeslots/coaching-timeslots-days-list/coaching-timeslots-day.helpers';

import styles from './reschedule.module.scss';

const Reschedule = () => {
  const dispatch = useAppDispatch();
  const [showRescheduledModal, setShowRescheduledModal] = useState(false);
  const [newTimeslot, setNewTimeslot] = useState<number>(null);
  const [userInfoName, setUserInfoName] = useState('');
  const { id: scheduledSessionId } = useParams();
  const navigate = useNavigate();
  const [user] = useState(localStorageUser());
  const [events, updateEvents] = useImmer<EventInput[]>([]);
  const {
    capacityExceededModalVisible,
    onDatesSet,
    onTimeslotSelected,
    setCapacityExceededApproved,
    setCapacityExceededModalVisible,
    timeslotsData,
  } = useCoachTimeslots({
    coachId:
      localStorage.getItem('cognitoGroup') === 'coach'
        ? localStorageUser().id
        : JSON.parse(localStorage.getItem('assignedCoach')!).id,
  });

  const isCoach = localStorage.getItem('cognitoGroup') === 'coach';

  const coachSessionData = useSesssionDetailsFetch(
    isCoach ? (scheduledSessionId as string) : null,
  );

  useEffect(() => {
    if (
      !isCoach &&
      (timeslotsData?.remaining_weekly_capacity <= 0 ||
        timeslotsData?.remaining_new_students_weekly_capacity <= 0)
    ) {
      updateEvents([]);
      return;
    }

    const currentEvent = {
      end: add30Minutes(+coachSessionData?.session?.session_date),
      id: coachSessionData?.session?.id,
      start: +coachSessionData?.session?.session_date,
    } as EventInput;

    updateEvents([
      ...(timeslotsData?.timeslots || [])?.map(timeslot => {
        return {
          display: 'background',
          end: new Date(+timeslot.end_time),
          groupId: 'available',
          id: timeslot.id,
          start: new Date(+timeslot.start_time),
        } as EventInput;
      }),
      {
        ...currentEvent,
        color: 'red',
        display: 'background',
        groupId: 'not-available',
      } as EventInput,
      {
        ...currentEvent,
        id: uuidV4(),
        title: `Apt. ${
          isCoach ? coachSessionData?.session?.student?.name : user?.name
        }`,
      } as EventInput,
    ]);
  }, [timeslotsData?.timeslots]);

  const selectedEvent = useMemo(() => {
    return events.find(event => {
      return !event.groupId;
    });
  }, [events]);

  const handleBackAction = () => {
    setShowRescheduledModal(false);
    dispatch(setOverlay(false));

    switch (localStorage.getItem('cognitoGroup')) {
      case null:
        navigate('/login');
        break;
      case 'coachee':
        if (user.status === STATUSES.ACTIVE) {
          navigate('/coachee/dashboard');
        } else if (user.status === STATUSES.REGISTER_CHOOSE_YOUR_COACH) {
          navigate('/coachee/coach-matching');
        } else if (user.status === STATUSES.CHANGE_COACH) {
          navigate('/coachee/coach-matching');
        } else {
          navigate('/coachee/register');
        }
        break;
      case 'coach':
        navigate('/coach/dashboard');
        break;
      default:
        navigate('/login');
        break;
    }
  };

  const rescheduledModal = (
    <EmptyModal
      cssClass="mediumModal"
      handleClose={() => {
        setShowRescheduledModal(false);
        dispatch(setOverlay(false));
        handleBackAction();
      }}
    >
      <div className={styles.modalContainer}>
        <div className={styles.modalTitle}>
          Session rescheduled successfully
        </div>
        <UserInfo
          cssClass="paddingBottom100"
          name={userInfoName}
          timeslot={newTimeslot}
          userExternal={JSON.parse(localStorage.getItem('assignedCoach')!)}
        />

        <div className={styles.modalButtonContainer}>
          <Button
            isDisabled={false}
            label="Close"
            onClickHandler={() => {
              return handleBackAction();
            }}
          />
        </div>
      </div>
    </EmptyModal>
  );

  useEffect(() => {
    if (newTimeslot !== null) {
      setUserInfoName(
        localStorage.getItem('cognitoGroup') === 'coach'
          ? `${coachSessionData.session.student.name} ${coachSessionData.session.student.surname}`
          : '',
      );
      setShowRescheduledModal(true);
      dispatch(setOverlay(true));
    }
  }, [newTimeslot]);

  const handleReschedule = async () => {
    try {
      const timeslot = findOverlapBackgroundEvent(
        selectedEvent,
        timeslotsData?.timeslots,
      );

      const response = await rescheduleSession(
        timeslot?.id,
        scheduledSessionId,
      );

      setNewTimeslot(response?.data?.session_date);
    } catch {
      toast.error('Could not reschedule the session');
    }
  };

  const handleSelect = async (arg: DateSelectArg) => {
    const newEvent = {
      constraint: 'available',
      durationEditable: false,
      editable: true,
      end: arg.end,
      id: uuidV4(),
      start: arg.start,
      title: `Apt. ${
        isCoach ? coachSessionData?.session?.student?.name : user?.name
      }`,
    };

    const updatedEvents = [
      ...events.filter(event => {
        return event.groupId;
      }),
      newEvent,
    ];

    updateEvents(updatedEvents);

    onTimeslotSelected();
  };

  const handleEventDrop = async (arg: EventDropArg) => {
    updateEvents(draft => {
      const eventIndex = draft.findIndex(event => {
        return event.id === arg.event.id;
      });

      if (eventIndex === -1) {
        return;
      }

      draft[eventIndex].start = arg.event.start;
      draft[eventIndex].end = arg.event.end;
    });
  };

  const selectAllow = (selectInfo: any) => {
    const { end, start } = selectInfo;

    return events.some(event => {
      return start < event.end && end > event.start;
    });
  };

  const canReschedule = useMemo(() => {
    if (!isCoach) {
      return selectedEvent;
    }

    return (
      selectedEvent &&
      coachSessionData?.session &&
      formatDate(selectedEvent?.start as Date, 'MMM D, YYYY HH:mm:ss') !==
        formatDate(coachSessionData?.session?.timeslot, 'MMM D, YYYY HH:mm:ss')
    );
  }, [selectedEvent, coachSessionData?.session, isCoach]);

  const canSelect = isCoach || timeslotsData?.remaining_weekly_capacity > 0;

  const handleConfirm = () => {
    setCapacityExceededModalVisible(false);
    setCapacityExceededApproved(true);
  };

  const hideModal = () => {
    setCapacityExceededModalVisible(false);
  };

  return (
    <UserLayout centeredTitle title="Reschedule session">
      <CalendarWrapper>
        <FullCalendar
          allDaySlot={false}
          businessHours={false}
          datesSet={onDatesSet}
          displayEventTime={false}
          editable
          eventDrop={handleEventDrop}
          eventDurationEditable={false}
          events={events || []}
          height="calc(100vh - 240px)"
          initialView="timeGridWeek"
          noEventsText="No available timeslots"
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          scrollTimeReset={false}
          select={handleSelect}
          selectable={canSelect}
          selectAllow={selectAllow}
          selectOverlap
          unselectAuto={false}
          validRange={{
            end: isCoach ? null : addWeeks(firstDateOfWeek(), 3),
            start: isCoach
              ? addToDate(new Date(), 15, 'minutes')
              : addToDate(new Date(), 36, 'hours'),
          }}
        />
      </CalendarWrapper>

      {/* Toggle the modal showing for a successful rescheduling */}
      <Conditional condition={showRescheduledModal}>
        <div>{addModalToPortal(rescheduledModal)}</div>
      </Conditional>

      <Conditional condition={timeslotsData?.timeslots?.length > 0}>
        <div className={styles.rescheduleButtonContainer}>
          <Button
            isDisabled={!canReschedule}
            label="Reschedule"
            onClickHandler={handleReschedule}
          />
        </div>
      </Conditional>

      <Conditional condition={capacityExceededModalVisible}>
        <EmptyModal cssClass="height50" handleClose={hideModal}>
          <div className={styles.modalContainer}>
            <Typography
              text={
                timeslotsData?.remaining_weekly_capacity <= 0
                  ? 'Weekly capacity exceeded'
                  : 'Weekly capacity for new students exceeded'
              }
              variant="subtitle1"
            />

            <Typography
              text={`You have exceeded your ${
                timeslotsData?.remaining_weekly_capacity <= 0
                  ? 'capacity'
                  : 'capacity for new students'
              } this week. Are you sure you want to continue?`}
            />

            <div className={styles.actions}>
              <Button
                isDisabled={false}
                label="Yes, I am sure"
                onClickHandler={handleConfirm}
              />

              <Typography
                paddingTop="x"
                text="Cancel"
                textStyle="bold"
                onClick={hideModal}
              />
            </div>
          </div>
        </EmptyModal>
      </Conditional>
    </UserLayout>
  );
};

export default Reschedule;
