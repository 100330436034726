import { Fragment, useEffect, useState } from 'react';
import InterestCard from '../interest-card/interest-card';
import { CoachMatch } from '../../interfaces/CoachMatch';
import Avatar from '../../../general/components/avatar/avatar';
import { Flex, Typography } from '../../../../common';

import styles from './user-card.module.scss';
import generalStyles from '../general/general.module.scss';

type Props = {
  coach: CoachMatch;
  onLearnMore: (coach: CoachMatch) => void;
  onSelect: (coach: CoachMatch, isSelected: boolean) => void;
  selected: boolean;
};

const UserCard = ({ coach, onLearnMore, onSelect, selected }: Props) => {
  const [isSelected, setIsSelected] = useState(selected);

  useEffect(() => {
    setIsSelected(selected);
  }, [selected]);

  const handleSelect = () => {
    setIsSelected(!isSelected);
  };

  //* Renders

  const nameAndPercentageRender = (
    <Flex alignItems="center" gap="x">
      <Typography
        color="info"
        text={`${coach.name ?? ''} ${
          coach.surname ? coach.surname?.charAt(0)?.toUpperCase() : ''
        }.`}
        textStyle="bold"
        variant="body1"
      />

      <span className={styles.percentage}>
        ({coach.match_percentage || 0}% match)
      </span>
    </Flex>
  );

  // TODO: gender should be read from the gender property of the response
  const preferencesRender = (
    <span>
      {coach.filteredPreferences &&
        coach.filteredPreferences.length > 0 &&
        coach.filteredPreferences.map((item, index) => {
          return (
            <span key={index}>
              {index < coach.preferences.length - 1
                ? `${item.label}, `
                : item.label}
            </span>
          );
        })}
    </span>
  );

  const interestsRender = (
    <div className={generalStyles.interestsListing}>
      <Fragment>
        {coach.filteredInterests &&
          coach.filteredInterests.map(interest => {
            return <InterestCard interest={interest} key={interest.label} />;
          })}
      </Fragment>
    </div>
  );

  const languagesRender = (
    <div className={styles.languages}>
      <span className={styles.languageLabel}>Language</span>:{' '}
      <Fragment>
        {coach.coaching_language.map((language, index) => {
          return `${language.charAt(0).toUpperCase()}${language.slice(1)}${
            index === coach.coaching_language.length - 1 ? '' : ', '
          }`;
        })}
      </Fragment>
    </div>
  );

  const learnMoreRender = (
    <div
      className={generalStyles.learnMore}
      data-testid="learn-more"
      onClick={e => {
        e.stopPropagation();
        onLearnMore(coach);
      }}
    >
      Learn more
    </div>
  );

  return (
    <div
      className={`${styles.container} ${isSelected ? styles.selected : ''}`}
      onClick={() => {
        handleSelect();

        onSelect(coach, !isSelected);
      }}
    >
      <div className={generalStyles.userBasicInfoContainer}>
        <Avatar image={coach.profile_img} name={coach.name} size="small" />
        <div className={generalStyles.basicInfo}>
          {nameAndPercentageRender}
          {preferencesRender}
          {languagesRender}
          {learnMoreRender}
        </div>
      </div>
      <div className={generalStyles.separator} />

      <div className={generalStyles.interestsBar}>INTERESTED IN</div>
      {interestsRender}
    </div>
  );
};

export default UserCard;
