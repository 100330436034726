import React, { FC, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import toast from 'react-hot-toast';
import { AxiosError } from 'axios';
import InputMask from 'react-input-mask';
import {
  Button,
  Conditional,
  Flex,
  Form,
  Typography,
} from '../../../../../../common';
import { InputV2 } from '../../../../../register-coachee/components/ui/Input-v2';
import { EditFormProps } from './edit-form.types';
import { adminFormSchema } from '../create-form/create-form.schema';
import Avatar from '../../../../../general/components/avatar/avatar';
import Tabs from '../../../../../general/components/tabs/tabs';
import axiosInstance from '../../../../../../utils/helpers/http';
import {
  phoneToE164Format,
  phoneToReadableFormat,
} from '../../../../../../utils/helpers/helpers';

export const EditAdminForm: FC<EditFormProps> = props => {
  const { admin, onHideDrawer, onRefresh } = props;
  const [activeTab, setActiveTab] = useState('Details');

  const { formState, handleSubmit, register } = useForm({
    defaultValues: {
      ...admin,
      phone_number: phoneToReadableFormat(admin.phone_number),
    },
    resolver: yupResolver(adminFormSchema),
  });

  const { errors } = formState;

  // @ts-ignore
  const submit = async data => {
    try {
      await axiosInstance.put('admin/admins', {
        ...data,
        phone_number: phoneToE164Format(data.phone_number),
        profile_img: admin.profile_img ?? '',
      });
      toast.success('Admin updated successfully');
      onRefresh();
      onHideDrawer();
      // @ts-ignore
    } catch (error: AxiosError<any>) {
      toast.error(
        error?.response?.data?.message ||
          'An error occurred while updating admin',
      );
    }
  };

  return (
    <Form
      Footer={
        activeTab === 'Details' && (
          <React.Fragment>
            <Button
              iconPosition="right"
              text="Discard"
              variant="action"
              onClick={onHideDrawer}
            />
            <Button iconPosition="right" text="Save Changes" type="submit" />
          </React.Fragment>
        )
      }
      Header={
        <React.Fragment>
          <Flex alignItems="center" gap="3x" marginBottom="4x">
            <Avatar height={80} image={admin.profile_img} width={80} />

            <div>
              <Typography text={admin?.name} variant="title3" />
              <Typography
                marginTop=".5x"
                text="Active Coach"
                variant="caption"
              />
            </div>
          </Flex>

          <Tabs active={activeTab} tab1="Details" onTabChange={setActiveTab} />
        </React.Fragment>
      }
      onClose={onHideDrawer}
      onSubmit={handleSubmit(submit)}
    >
      <Conditional condition={activeTab === 'Details'}>
        <Flex direction="column" gap="2x">
          <InputV2
            errorMessage={errors.name?.message}
            label="Name"
            placeholder="Enter name"
            {...register('name')}
          />

          <InputV2
            errorMessage={errors.name?.message}
            label="Surname"
            placeholder="Enter surname"
            {...register('surname')}
          />

          <InputV2
            errorMessage={errors.email?.message}
            label="Email"
            placeholder="email@domain.com"
            {...register('email')}
          />

          <InputMask mask="+1 (999) 999-9999" {...register('phone_number')}>
            {
              // @ts-ignore
              (inputProps: any) => {
                return (
                  <InputV2
                    {...inputProps}
                    errorMessage={errors.phone_number?.message}
                    label="Phone"
                    placeholder="+1 234 567 8912"
                    type="tel"
                  />
                );
              }
            }
          </InputMask>
        </Flex>
      </Conditional>
    </Form>
  );
};
