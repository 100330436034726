import * as yup from 'yup';

export const campusFormSchema = yup.object().shape({
  city: yup.string().required('City is required'),
  contactEmail: yup
    .string()
    .email('Invalid email')
    .required('Contact Email is required'),
  contactFullName: yup.string().required('Contact Full Name is required'),
  country: yup.string().required('Country is required'),
  mailingAddress: yup.string().required('Mailing Address is required'),
  name: yup.string().required('Name is required'),
  state: yup.string().required('State is required'),
});
