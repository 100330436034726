import React, { FC, useMemo, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import useSWR from 'swr';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import groupBy from 'lodash/groupBy';
import uniqBy from 'lodash/uniqBy';
import { interceptorFetcher } from '../../../../utils/helpers/interceptorFetcher';
import { CoachSessionTimeslotCard } from '../../../coach/interfaces/CoachSessionTimeslotCard';
import {
  addMinutes,
  convertTimeInMillisecondsToLocalDate,
  parseDate,
} from '../../../../utils/helpers/date';
import { Toggle } from '../../../../common';
import { Drawer } from '../drawer';
import { AvailabilityDrawer } from './components';
import { AvailabilityDetailsCoach } from './components/availability-drawer/availability-drawer.types';

import styles from './calendar.module.scss';

export const Calendar: FC = () => {
  const [showAvailability, setShowAvailability] = useState(false);
  const [availabilityDrawerVisible, setAvailabilityDrawerVisible] =
    useState(false);
  const [activeCoaches, setActiveCoaches] = useState<
    AvailabilityDetailsCoach[]
  >([]);

  const { data } = useSWR('admin/all-session-timeslots', interceptorFetcher, {
    revalidateOnFocus: false,
  });

  const { data: availabilityData } = useSWR(
    showAvailability ? 'admin/all-available-timeslots' : null,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const events = useMemo(() => {
    if (data) {
      return data?.map((session: any) => {
        const localDate = convertTimeInMillisecondsToLocalDate(
          session.session_date,
        );

        return {
          backgroundColor: '#73be76',
          borderColor: '#73be76',
          display: 'auto',
          end: addMinutes(localDate, 30),
          extendedProps: session,
          id: session.id,
          start: localDate,
          title: `${session.coach_name || ''} ${session.coach_surname || ''}`,
        } as EventInput;
      });
    }

    return [];
  }, [availabilityData]);

  const availableEvents = useMemo(() => {
    debugger;
    if (availabilityData) {
      const groupedData = groupBy(availabilityData, item => {
        return item.session_date.split('T')[0];
      });

      return Object.keys(groupedData).map(key => {
        const coachesCount = uniqBy(groupedData[key], 'coach_id').length;
        const session = groupedData[key][0];
        const localDate = parseDate(key);

        return {
          backgroundColor: '#73be76',
          borderColor: '#73be76',
          className: styles.event,
          display: 'auto',
          end: addMinutes(localDate, 30),
          extendedProps: {
            coaches: groupedData[key],
          },
          id: session.id,
          start: localDate,
          title: `${coachesCount} ${
            coachesCount === 1 ? 'coach has' : 'coaches have'
          } availability.`,
        } as EventInput;
      });
    }

    return [];
  }, [availabilityData]);

  const handleEventClick = (event: EventClickArg) => {
    const coaches = event.event.extendedProps.coaches.map(
      (item: CoachSessionTimeslotCard) => {
        return {
          name: item.coach_name,
          surname: item.coach_surname,
        };
      },
    );
    setActiveCoaches(coaches);
    setAvailabilityDrawerVisible(true);
  };

  return (
    <Drawer
      Content={
        <AvailabilityDrawer
          coaches={activeCoaches}
          onHideDrawer={() => {
            return setAvailabilityDrawerVisible(false);
          }}
        />
      }
      open={availabilityDrawerVisible}
    >
      <div className={styles.container}>
        <div className={styles.toggleWrapper}>
          <Toggle
            label="Availability"
            value={showAvailability}
            onToggle={setShowAvailability}
          />
        </div>

        <FullCalendar
          allDaySlot={false}
          businessHours={false}
          displayEventTime={false}
          editable
          eventClick={handleEventClick}
          eventDurationEditable={false}
          events={showAvailability ? availableEvents : events}
          height="calc(100vh - 40px)"
          initialView="dayGridMonth"
          plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
          scrollTimeReset={false}
          selectable
          selectOverlap
          unselectAuto={false}
        />
      </div>
    </Drawer>
  );
};
