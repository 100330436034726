import React, { FC, useMemo, useState } from 'react';
import { ColumnDef } from '@tanstack/react-table';
import useSWR, { mutate } from 'swr';
import toast from 'react-hot-toast';
import { Flex, Icon, WrapperMenu } from '../../../../common';
import { Coach, CoachStatus } from '../../../../types';
import { EntitySection } from '../entity-section';
import { Drawer } from '../drawer';
import { CoachDrawer } from './components';
import { interceptorFetcher } from '../../../../utils/helpers/interceptorFetcher';
import { StudentDrawerProps } from '../students/components/student-drawer/student-drawer.types';
import { Menu, MenuItem } from '../../../../common/menu';
import axiosInstance from '../../../../utils/helpers/http';

export const Coaches: FC = () => {
  const [currentDrawerType, toggleCurrentDrawerType] =
    useState<StudentDrawerProps['type']>(null);
  const [search, setSearch] = useState('');
  const [currentCoach, setCurrentCoach] = useState<Coach>(null);

  const { data } = useSWR(
    `admin/coaches?search=${search}`,
    interceptorFetcher,
    {
      revalidateOnFocus: false,
    },
  );

  const handleRefresh = () => {
    mutate(`admin/coaches?search=${search}`);
  };

  const archiveCoach = async (coachId: string) => {
    try {
      await axiosInstance.put('admin/set-coach-status', {
        id: coachId,
        status: 'Archived',
      });
      handleRefresh();
      toast.success('Coach archived successfully');
    } catch {
      toast.error('An error occurred while archiving coach');
    }
  };

  const columns: ColumnDef<Coach, keyof Coach>[] = useMemo(() => {
    return [
      {
        accessorKey: 'name',
        header: 'Name',
      },
      {
        accessorKey: 'school',
        header: 'School',
      },
      {
        accessorKey: 'city',
        header: 'City',
      },
      {
        accessorKey: 'state',
        header: 'State',
      },
      {
        accessorKey: 'studentsCount',
        header: 'Students',
      },
      {
        accessorKey: 'weekly_capacity',
        header: 'Weekly capacity',
      },
      {
        accessorKey: 'new_students_capacity',
        header: 'New students capacity',
      },
      {
        accessorKey: 'sessionsRating',
        header: 'Session Rating',
      },
      {
        accessorKey: 'pastSessionsCount',
        header: 'Past Sessions',
      },
      {
        cell: ({ row: { original } }) => {
          return (
            <Flex alignItems="center" gap="2x" justifyContent="flex-end">
              <div
                onClick={event => {
                  event.stopPropagation();
                  event.preventDefault();
                  setCurrentCoach(original);
                  toggleCurrentDrawerType('edit');
                }}
              >
                <Icon color="t2" name="actions:pencil" variant="button" />
              </div>

              <WrapperMenu
                Menu={
                  <Menu headerText="Update Status">
                    <MenuItem
                      text="Archive Coach"
                      onClick={() => {
                        return archiveCoach(original.id);
                      }}
                    />
                  </Menu>
                }
              />
            </Flex>
          );
        },
        header: 'Actions',
      },
    ];
  }, []);

  return (
    <Drawer
      Content={
        <CoachDrawer
          coach={currentDrawerType !== 'create' ? currentCoach : null}
          type={currentDrawerType}
          onEdit={() => {
            toggleCurrentDrawerType('edit');
          }}
          onHideDrawer={() => {
            return toggleCurrentDrawerType(null);
          }}
          onRefresh={handleRefresh}
        />
      }
      open={currentDrawerType !== null}
    >
      <EntitySection
        columns={columns}
        data={data}
        getRowRibbonColor={({ original }) => {
          if (original.status === CoachStatus.Archived) {
            return 'red';
          }
        }}
        name="Coach"
        pluralName="Coaches"
        onCreate={() => {
          return toggleCurrentDrawerType('create');
        }}
        onSearch={setSearch}
        onShowDetails={campus => {
          setCurrentCoach(campus.original);
          return toggleCurrentDrawerType('details');
        }}
      />
    </Drawer>
  );
};
