import React, { useEffect, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { useImmer } from 'use-immer';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import { Dashboard } from '../../../utils/constants/DashboardConstants';
import Tabs from '../../../../general/components/tabs/tabs';
import { CoachSessionTimeslotCard } from '../../../interfaces/CoachSessionTimeslotCard';
import { useSessionsFetch } from '../../../api/useSessionsFetch';
import Loading from '../../../../general/components/loading/loading';
import EmptyListing from '../../../../general/components/empty-listing/empty-listing';
import emptySessions from '../../../../../assets/icons/EmptyListing/emptySessions.svg';
import { useAppDispatch, useAppSelector } from '../../../../../store/hooks';
import { setDashboardSessionFilter } from '../../../store/coachSlice';
import { CalendarWrapper, Conditional } from '../../../../../common';
import {
  addMinutes,
  addToCurrentDate,
  convertTimeInMillisecondsToLocalDate,
} from '../../../../../utils/helpers/date';
import { renderEventContent } from '../../../../../helpers/full-calendar';

import styles from './SessionListing.module.scss';
import generalStyles from '../../../../general/styles/General.module.scss';

const SessionListing = () => {
  const dispatch = useAppDispatch();
  const selectedTab = useAppSelector(state => {
    return state.coach.dashboardSessionsFilter;
  });
  const [events, updateEvents] = useImmer<EventInput[]>([]);
  const navigate = useNavigate();
  const calendarRef = useRef<FullCalendar>();

  const sessionsData = useSessionsFetch(
    selectedTab === Dashboard.upcoming ? 'upcoming' : 'past',
  );

  useEffect(() => {
    if (sessionsData?.sessions) {
      updateEvents(
        sessionsData?.sessions?.map((session: CoachSessionTimeslotCard) => {
          const localDate = convertTimeInMillisecondsToLocalDate(
            session.session_date,
          );

          return {
            backgroundColor: '#73be76',
            borderColor: '#73be76',
            display: 'auto',
            end: addMinutes(localDate, 30),
            extendedProps: session,
            id: session.id,
            start: localDate,
            title: `${session.student_name} ${session.student_surname}`.trim(),
          } as EventInput;
        }),
      );
    }
  }, [sessionsData?.sessions]);

  return (
    <div>
      <Tabs
        active={selectedTab}
        tab1={Dashboard.upcoming}
        tab2={Dashboard.history}
        onTabChange={(data: string) => {
          dispatch(setDashboardSessionFilter(data));

          if (calendarRef.current) {
            calendarRef.current
              ?.getApi()
              .changeView(
                data === Dashboard.upcoming ? 'timeGridWeek' : 'listWeek',
              );
          }
        }}
      />

      <div
        className={`${styles.container} ${
          selectedTab === Dashboard.history ? styles.additionalPadding : ''
        }`}
      >
        <Conditional condition={selectedTab !== Dashboard.history}>
          <Link to="/coach/session/new">
            <div
              className={`${generalStyles.emptyBackgroundButton} ${styles.createNewSessionButton}`}
            >
              Create new session
            </div>
          </Link>
        </Conditional>

        <div className={styles.listingContainer}>
          <Conditional
            condition={!sessionsData.isLoading}
            Fallback={<Loading />}
          >
            <Conditional
              condition={events?.length > 0}
              Fallback={
                <EmptyListing
                  image={emptySessions}
                  subtitle={`${
                    selectedTab === Dashboard.upcoming ? 'Upcoming' : 'History'
                  } sessions will appear here`}
                  title="No sessions yet"
                />
              }
            >
              <CalendarWrapper>
                <FullCalendar
                  allDaySlot={false}
                  businessHours={false}
                  dayHeaderFormat={{ day: 'numeric', weekday: 'short' }}
                  eventClick={(arg: EventClickArg) => {
                    navigate(`/coach/session/${arg.event.id}`);
                  }}
                  eventContent={renderEventContent}
                  eventDurationEditable={false}
                  events={events}
                  headerToolbar={{
                    center: 'title',
                    left: 'prev,next',
                    right: 'timeGridWeek,listWeek',
                  }}
                  height={
                    selectedTab === Dashboard.upcoming
                      ? 'calc(100vh - 360px)'
                      : 'calc(100vh - 325px)'
                  }
                  initialView={
                    selectedTab === Dashboard.upcoming
                      ? 'timeGridWeek'
                      : 'listWeek'
                  }
                  noEventsText="No sessions yet"
                  plugins={[
                    timeGridPlugin,
                    dayGridPlugin,
                    interactionPlugin,
                    listPlugin,
                  ]}
                  ref={calendarRef}
                  scrollTimeReset={false}
                  selectable
                  selectOverlap
                  unselectAuto={false}
                  validRange={
                    selectedTab === Dashboard.upcoming
                      ? { start: new Date() }
                      : { end: addToCurrentDate(-1, 'day') }
                  }
                  views={{
                    listWeek: { buttonText: 'List', displayEventTime: true },
                    timeGridWeek: {
                      buttonText: 'Calendar',
                      displayEventTime: false,
                    },
                  }}
                />
              </CalendarWrapper>
            </Conditional>
          </Conditional>
        </div>
      </div>
    </div>
  );
};

export default SessionListing;
