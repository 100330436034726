import React, { useCallback, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';
import { Auth, Hub } from 'aws-amplify';
import {
  buttons,
  initialPasswordData,
  registerStudentSteps,
  stepAddItem,
  stepKeys,
  stepModalPlaceholders,
  stepModalTitles,
  stepValidationErrors,
} from '../../utils/constants/RegisterStudent';
import { getPersonAge } from '../../../../utils/helpers/helpers';
import CurrentStep from '../../../general/components/current-step/current-step';
import Button from '../ui/Button/Button';
import { ExtendableListing } from '../../../../utils/interfaces/RegisterStudent';
import AddItemButton from '../ui/AddItemButton/AddItemButton';
import Modal from '../ui/Modal/Modal';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import {
  setCurrentStep,
  setIsButtonDisabled,
} from '../../store/registerStudentSlice';

import { Student } from '../../interfaces/Student';
import { createStudent } from '../../api/createStudent';
import { STATUSES } from '../../utils/constants/Statuses';
import { updateStudent } from '../../api/updateStudent';

import useAuth from '../../../authentication/hooks/UseAuth';
import { APP_ROUTES } from '../../../../routes/constants';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { addModalToPortal } from '../../../general/utils/helpers/addModalToPortal';
import { setOverlay } from '../../../general/store/generalSlice';
import EmptyModal from '../../../general/components/empty-modal/empty-modal';
import { Layout, Typography } from '../../../../common';

import styles from './Wizard.module.scss';

const Wizard = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { cognitoResendConfirmationCode, cognitoUserSignUp } = useAuth();
  const [errors, setErrors] = useState({});
  const [isDisabled, setIsDisabled] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [passwordData, setPasswordData] = useState(initialPasswordData);
  const [otpCode, setOtpCode] = useState('');
  const [isAuthenticated, setisAuthenticated] = useState<boolean>(false);
  const [isConfirmPhoneModalOpen, setIsConfirmPhoneModalOpen] = useState(false);

  const listener = async (data: any) => {
    if (data.payload.event === 'autoSignIn') {
      if (!isAuthenticated) {
        setisAuthenticated(true);
      }
    }
  };

  Hub.listen('auth', listener);
  useEffect(() => {
    const updateStudentHandler = async () => {
      const response = await updateStudent({
        status: STATUSES.REGISTER_MORE_ABOUT_YOU,
      });
      localStorage.setItem('user', JSON.stringify(response?.data));
      dispatch(setCurrentStep(currentStep.nextStepKey!));
      Hub.remove('auth', listener);
    };
    if (isAuthenticated) {
      updateStudentHandler();
    }
  }, [isAuthenticated]);

  // //* Set the state - current screen is shown based on the step
  useEffect(() => {
    const user = localStorageUser();
    if (user) {
      switch (user.status) {
        case STATUSES.REGISTER_VERIFY_PHONE_NUMBER:
        case STATUSES.REGISTER_MORE_ABOUT_YOU:
        case STATUSES.REGISTER_PARENTAL_CONSENT:
        case STATUSES.REGISTER_PENDING_SCHOOL_ENTRY:
        case STATUSES.REGISTER_CHOOSE_YOUR_COACH:
        case STATUSES.REGISTER_COACHING_ACKNOWLEDGEMENT: {
          dispatch(setCurrentStep(calculateStep()!));
          break;
        }
        case STATUSES.WAITLIST:
          navigate('/coachee/coach-matching');
          break;
        default:
          break;
      }
    }
  }, [dispatch]);

  const currentStepKey = useAppSelector(state => {
    return state.registerStudent.currentStep;
  });

  const currentStep = registerStudentSteps[currentStepKey];

  // //* Calculate the step to be shown based on the user
  const calculateStep = () => {
    const student = JSON.parse(localStorage.getItem('user')!);
    if (student !== null) {
      if (student.status !== STATUSES.ACTIVE) {
        switch (student.status) {
          case STATUSES.REGISTER_VERIFY_PHONE_NUMBER:
            return stepKeys.verifyPhoneNumber;
          case STATUSES.REGISTER_MORE_ABOUT_YOU:
            return stepKeys.aboutYou;
          case STATUSES.REGISTER_PARENTAL_CONSENT:
            return stepKeys.parentalConsent;
          case STATUSES.REGISTER_COACHING_ACKNOWLEDGEMENT:
            return stepKeys.coachingAcknowledgment;
          // case STATUSES.REGISTER_PENDING_PARENTAL_CONSENT:
          //   return stepKeys.pendingParentalConsent;
          case STATUSES.REGISTER_PENDING_SCHOOL_ENTRY:
            return stepKeys.pendingSchoolEntry;
          default:
        }
      }
    } else {
      return stepKeys.topics;
    }
  };

  const currentStepErrors = useAppSelector(state => {
    return state.registerStudent.errors[currentStepKey];
  });

  const isStoreButtonDisabled = useAppSelector(state => {
    return state.registerStudent.isButtonDisabled;
  });

  //* Get the appropriate listing based from store based on the current step
  const listing = useAppSelector(state => {
    return state.registerStudent[
      currentStepKey as keyof typeof state.registerStudent
    ];
  }) as ExtendableListing;
  // const backroundBlur = useAppSelector((state) => state.general.backgroundBlur);

  //* Get the initial empty data from store
  const data: { [key: string]: any } = useAppSelector(state => {
    return state.registerStudent.userData;
  });

  //* Validation of the step based on how many items the user has chosen
  const validateChild = useCallback(async () => {
    if (currentStep.key === stepKeys.coachPreferences) {
      setIsDisabled(false);
    } else if (currentStep.objectValidation) {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      Object.keys(data[currentStep.key]).length === 0
        ? setIsDisabled(true)
        : setIsDisabled(false);
    } else if (currentStep.customValidation) {
      setIsDisabled(
        (currentStepErrors && Object.keys(currentStepErrors).length > 0) ||
          isStoreButtonDisabled,
      );
    }
  }, [data, currentStep, isStoreButtonDisabled, currentStepErrors]);

  //* Create a student instance from the collected data
  const studentInstance = () => {
    const student: Student = {
      cognito_id: localStorage.getItem('cognito_id')!,
      email: data.basicInfo.email,
      interests: Object.keys(data.interests),
      name: data.basicInfo.name,
      phone_number: data.basicInfo.phoneNo,
      preferences: Object.keys(data.coachPreferences),
      preferred_communication_channel: Object.keys(
        data.preferredCommunication,
      )[0],
      preferred_time: Object.keys(data.preferredTime),
      status: STATUSES.REGISTER_VERIFY_PHONE_NUMBER.toString(),
      student_campus_id: null,
      surname: data.basicInfo.lastName,
      topics: Object.keys(data.topics),
    };

    return student;
  };

  //* Handle going backwards in the wizard
  const handlePreviousStep = () => {
    setErrors({});

    if (currentStep.prevStepKey === stepKeys.login) {
      navigate('/login');
    }

    if (currentStep.prevStepKey === stepKeys.welcome) {
      navigate('/');
    }

    if (
      currentStep.prevStepKey &&
      registerStudentSteps[currentStep.prevStepKey]
    ) {
      dispatch(setCurrentStep(currentStep.prevStepKey));
      toast.dismiss();
    }
  };

  //* Handle going forward in the wizard
  const handleNextStep = async () => {
    setErrors({});
    toast.dismiss();

    if (
      // currentStep.key === stepKeys.pendingParentalConsent ||
      currentStep.key === stepKeys.pendingSchoolEntry
    ) {
      window.location.replace('https://www.trulacampus.org/');
    }
    if (
      currentStep.nextStepKey &&
      registerStudentSteps[currentStep.nextStepKey] &&
      !isDisabled &&
      currentStepKey !== stepKeys.basicInfo
    ) {
      //* Verify phone number to Cognito
      if (currentStepKey === stepKeys.verifyPhoneNumber && !isDisabled) {
        try {
          await Auth.confirmSignUp(localStorageUser().email, otpCode!);
          return;
        } catch (error: any) {
          console.error(error);
          return toast.error(error.message);
        }
      }
      //* About you - update student
      else if (currentStepKey === stepKeys.aboutYou && !isDisabled) {
        try {
          const response = await updateStudent({
            campus_id: data.aboutYou.school,
            coach_type: data.aboutYou.coachType,
            coaching_language: data.aboutYou.coachingLanguage,
            date_of_birth: data.aboutYou.birthdate,
            how_did_you_hear_about_us: data.aboutYou.engagement,
            native_language: data.aboutYou.coachingLanguage,
            status:
              getPersonAge(data.aboutYou.birthdate) >= 18
                ? STATUSES.REGISTER_COACHING_ACKNOWLEDGEMENT
                : STATUSES.REGISTER_PARENTAL_CONSENT,
            timezone: data.aboutYou.timezone,
          });
          localStorage.setItem('user', JSON.stringify(response?.data));
        } catch (err: any) {
          toast.error(err.response.data.message[0]);
          return;
        }
        return checkValidAge(data[currentStep.key].birthdate);
      }
      //* Pending parental consent
      else if (currentStepKey === stepKeys.parentalConsent && !isDisabled) {
        try {
          const response = await updateStudent({
            parental_consent_contact:
              data.parentalConsent.email !== ''
                ? data.parentalConsent.email
                : data.parentalConsent.phoneNo,
            parental_consent_type:
              data.parentalConsent.email !== '' ? 'EMAIL' : 'SMS',
            status: STATUSES.REGISTER_COACHING_ACKNOWLEDGEMENT,
          });
          localStorage.setItem('user', JSON.stringify(response?.data));
        } catch (err: any) {
          toast.error(err.response.data.message[0]);
          return;
        }
      }
      //* Pending school entry
      else if (currentStepKey === stepKeys.schoolEntry && !isDisabled) {
        try {
          const response = await updateStudent({
            custom_campus: data.schoolEntry.customSchool,
            status: STATUSES.REGISTER_PENDING_SCHOOL_ENTRY,
          });
          localStorage.setItem('user', JSON.stringify(response?.data));
        } catch (err: any) {
          toast.error(err.response.data.message[0]);
          return;
        }
      }

      dispatch(setCurrentStep(currentStep.nextStepKey));
      dispatch(setIsButtonDisabled(true));
      toast.dismiss();
    }
    //* Basic info - create student
    else if (currentStepKey === stepKeys.basicInfo && !isDisabled) {
      setIsConfirmPhoneModalOpen(true);
      dispatch(setOverlay(true));
    } else if (
      currentStepKey === stepKeys.coachingAcknowledgment &&
      !isDisabled
    ) {
      try {
        const response = await updateStudent({
          acknowledgement: true,
          allow_participation:
            data.coachingAcknowledgment.allow_participation === 'true',
          status: STATUSES.REGISTER_CHOOSE_YOUR_COACH,
        });
        localStorage.setItem('user', JSON.stringify(response?.data));
        navigate(`/coachee/${APP_ROUTES.COACH_MATCHING}`);
      } catch (err: any) {
        toast.error(err.response.data.message[0]);
        return;
      }

      toast.dismiss();
    } else if (stepValidationErrors[currentStepKey]) {
      toast.error(stepValidationErrors[currentStepKey], {
        style: {
          fontFamily: 'Source Sans 3',
          fontSize: '0.875rem',
        },
      });
    }
  };

  const handlePhoneConfirm = async () => {
    //* Register user to Cognito
    const cognitoUser = {
      attributes: {
        email: data.basicInfo.email,
        family_name: data.basicInfo.lastName,
        given_name: data.basicInfo.name,
        name: data.basicInfo.email,
        phone_number: data.basicInfo.phoneNo,
      },
      autoSignIn: {
        enabled: true,
      },
      password: passwordData.data.password,
      username: data.basicInfo.email,
    };
    try {
      await cognitoUserSignUp(cognitoUser);
      //* Register user to DB
      try {
        const response = await createStudent(studentInstance());
        localStorage.setItem('user', JSON.stringify(response.data));
        localStorage.setItem('cognitoGroup', 'coachee');
        setIsConfirmPhoneModalOpen(false);
        dispatch(setOverlay(false));
        dispatch(setCurrentStep(stepKeys.verifyPhoneNumber));
      } catch (err: any) {
        return toast.error(err.message);
      }
    } catch (error: any) {
      return toast.error(error.message);
    }

    return toast.dismiss();
  };

  const handleResendConfirmationCode = async () => {
    try {
      await cognitoResendConfirmationCode({ username: data.basicInfo.email });
      toast.success('Verification code has been sent!');
    } catch (error: unknown) {
      const err = error as AxiosError;
      toast.error(err.message);
    }
  };

  //* Checking if the student being registered is young than 18 years and redirect it appropriately
  const checkValidAge = (age: string) => {
    if (getPersonAge(age) >= 18) {
      dispatch(setCurrentStep(stepKeys.coachingAcknowledgment));
    } else {
      dispatch(setCurrentStep(stepKeys.parentalConsent));
    }
  };

  //* Validate the data when a step is changed
  useEffect(() => {
    validateChild();
  }, [currentStep, isModalOpen, data, validateChild]);

  const confirmPhoneModal = (
    <EmptyModal
      cssClass={styles.modal}
      handleClose={() => {
        setIsConfirmPhoneModalOpen(false);
        dispatch(setOverlay(false));
      }}
      title="Confirm your phone number"
    >
      <div className={styles.modalContent}>
        <Typography
          color="secondary"
          marginBottom="4x"
          text={`Please check again if the following phone number is correct: ${data.basicInfo.phoneNo}`}
          variant="body1"
        />

        <div className={styles.buttonContainer}>
          <Button
            isDisabled={false}
            label="Yes, it's correct"
            onClickHandler={() => {
              handlePhoneConfirm();
            }}
          />
        </div>
        <div
          className={styles.discard}
          onClick={() => {
            setIsConfirmPhoneModalOpen(false);
            dispatch(setOverlay(false));
          }}
        >
          No, change number
        </div>
      </div>
    </EmptyModal>
  );

  const CurrentStepComponent = currentStep ? currentStep.component : '';
  return (
    <div className={styles.container}>
      <Layout
        Footer={
          <Button
            isDisabled={
              currentStep.button === buttons.backToWebsite ? false : isDisabled
            }
            label={currentStep.button || 'Continue'}
            onClickHandler={() => {
              return handleNextStep();
            }}
          />
        }
        hasBackButton={!!currentStep?.prevStepKey}
        subtitle={currentStep.subtitle}
        title={currentStep.title}
        onBackButtonClick={handlePreviousStep}
      >
        <CurrentStep className={`${styles.currentComponent}`}>
          <CurrentStepComponent
            data={data[currentStepKey]}
            errors={errors}
            isRegister
            listing={listing}
            onPasswordChange={(passData: any) => {
              return setPasswordData(passData);
            }}
            onResendConfirmationCode={() => {
              return handleResendConfirmationCode();
            }}
            onVerifyPhoneNumber={(data: string) => {
              return setOtpCode(data);
            }}
          />
          {stepAddItem[currentStepKey] &&
            !data.topics.hasOwnProperty('im_not_sure') && (
              <AddItemButton
                title={currentStep.addItemTitle}
                variant={currentStep.addItemVariant}
                onClickHandler={() => {
                  setIsModalOpen(true);
                  dispatch(setOverlay(true));
                }}
              />
            )}
        </CurrentStep>
      </Layout>

      {isModalOpen &&
        addModalToPortal(
          <Modal
            currentStepKey={currentStepKey}
            handleClose={() => {
              setIsModalOpen(!isModalOpen);
              dispatch(setOverlay(false));
            }}
            isRegister
            listing={listing}
            placeholder={stepModalPlaceholders[currentStepKey]}
            subtitle="Add all the you did not find in the list."
            title={stepModalTitles[currentStepKey]}
          />,
        )}
      {!!isConfirmPhoneModalOpen && addModalToPortal(confirmPhoneModal)}
    </div>
  );
};

export default Wizard;
