import moment from 'moment';
import { Fragment } from 'react';
import arrowForward from '../../../../assets/icons/common/arrow_forward_ios.svg';
import { useAppSelector } from '../../../../store/hooks';
import { Student } from '../../../register-coachee/interfaces/Student';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import { Conditional } from '../../../../common';
import { formatDate } from '../../../../utils/helpers/date';

import styles from './StudentCard.module.scss';

type Props = {
  disabled?: boolean;
  isConversationList?: boolean;
  onSelect: (student: Student, isSelected: boolean) => void;
  selected?: boolean;
  student: Student;
};

const StudentCard = ({
  disabled,
  isConversationList,
  onSelect,
  selected,
  student,
}: Props) => {
  const user = localStorageUser();
  const preferencesListing = useAppSelector(state => {
    return state.registerStudent.coachPreferences;
  });

  const filteredPreferences = preferencesListing.predefined.filter(
    preference => {
      return student.preferences.includes(preference.value);
    },
  );

  return (
    <div
      className={`${styles.container} ${selected ? styles.selected : ''}`}
      onClick={() => {
        return !disabled ? onSelect(student, !selected) : '';
      }}
    >
      <div className={styles.subcontainer}>
        <div className={styles.upper}>
          <div className={styles.avatar}>
            <img
              alt="profile pic"
              className={styles.avatar}
              src="https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png"
            />
          </div>
          <div className={styles.text}>
            <div
              className={styles.name}
            >{`${student.name} ${student.surname}`}</div>
            {!isConversationList && (
              <div className={styles.interests}>
                {filteredPreferences.map((preference, index) => {
                  return (
                    <span key={preference.label + index}>
                      {preference.label +
                        (index !== filteredPreferences.length - 1 ? ', ' : '')}
                    </span>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        {!isConversationList && <div className={styles.separator} />}
        {!isConversationList && (
          <div className={styles.lower}>
            {student.next_session ? (
              <div>
                <span className={styles.nextSessionLabel}>NEXT SESSION: </span>
                <span className={styles.nextSessionValue}>
                  <Conditional condition={!!user}>
                    <Fragment>
                      {formatDate(+student.next_session, 'hh:mm a')} -{' '}
                    </Fragment>
                  </Conditional>

                  {moment(
                    formatDate(+student.next_session, 'YYYY-MM-DD'),
                  ).calendar(null, {
                    lastDay: '[Yesterday] (MM/DD/YYYY)',
                    lastWeek: '[Last] dddd (MM/DD/YYYY)',
                    nextDay: '[Tomorrow] (MM/DD/YYYY)',
                    nextWeek: 'dddd (MM/DD/YY)',
                    sameDay: '[Today] (MM/DD/YYYY)',
                    sameElse: 'dddd (MM/DD/YYYY)',
                  })}
                </span>
              </div>
            ) : (
              <div>
                <span className={styles.nextSessionValue}>
                  No sessions scheduled
                </span>
              </div>
            )}
          </div>
        )}
      </div>
      <div className={styles.arrow}>
        <img alt="See more" src={arrowForward} />
      </div>
    </div>
  );
};

export default StudentCard;
