import { useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import toast from 'react-hot-toast';
import { Listing } from '../../../../utils/interfaces/RegisterStudent';
import { University } from '../../../register-coachee/interfaces/University';
import { countries, states } from '../../utils/constants/USStates';
import { regExpressions } from '../../../register-coachee/utils/constants/RegisterStudent';
import { useAppDispatch, useAppSelector } from '../../../../store/hooks';
import { updateCoach } from '../../api/updateCoach';
import { useUniversitiesFetch } from '../../../register-coachee/api/useUniversitiesFetch';
import { addCustomInterest } from '../../../register-coachee/store/registerStudentSlice';
import { localStorageUser } from '../../../../utils/helpers/localStorageUser';
import UserLayout from '../../../general/components/user-layout/user-layout';
import InterestCard from '../../../coach-matching/components/interest-card/interest-card';
import Button from '../../../register-coachee/components/ui/Button/Button';
import Input from '../../../register-coachee/components/ui/Input/Input';
import MaskedInput from '../../../register-coachee/components/ui/MaskedInput/MaskedInput';
import SelectInput from '../../../register-coachee/components/ui/SelectInput/SelectInput';
import Textarea from '../../../register-coachee/components/ui/Textarea/Textarea';
import Interests from '../../../register-coachee/components/interests/Interests';
import CoachPreferences from '../../../register-coachee/components/coach-preferences/CoachPreferences';
import CoachPersonalityDescriptors from '../../../register-coachee/components/coach-personality-descriptor/CoachPersonalityDescriptors';
import AddItemButton from '../../../register-coachee/components/ui/AddItemButton/AddItemButton';
import Modal from '../../../register-coachee/components/ui/Modal/Modal';
import Edit from '../../../../assets/icons/common/edit.svg';
import calendarIcon from '../../../../assets/icons/common/calendar.svg';
import { addModalToPortal } from '../../../general/utils/helpers/addModalToPortal';
import { setOverlay } from '../../../general/store/generalSlice';
import Avatar from '../../../general/components/avatar/avatar';
import { Coach } from '../../../authentication/interfaces';
import { Conditional, Typography } from '../../../../common';

import styles from './CoachProfileOverview.module.scss';

const CoachProfileOverview = () => {
  const [isEditing, setIsEditing] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalSelection, setModalSelection] = useState('');
  const [scrollToTop, setScrollToTop] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [user, setUser] = useState(localStorageUser<Coach>());
  const dispatch = useAppDispatch();

  const universities: Listing[] = useUniversitiesFetch().universities?.map(
    (item: University) => {
      return {
        label: item.name,
        value: item.name,
        ...item,
      };
    },
  );

  useEffect(() => {
    if (!isEditing) {
      window.scrollTo({
        behavior: 'smooth',
        top: 0,
      });
    } else {
      window.scrollTo({
        top: 0,
      });
    }
  }, [scrollToTop, isEditing]);

  useEffect(() => {
    setScrollToTop(!scrollToTop);
  }, [isEditing]);

  const [errors, setErrors] = useState({
    biography: '',
    campus_ids: '',
    city: '',
    coach_type: '',
    coaching_language: '',
    country: '',
    date_of_birth: '',
    email: '',
    name: '',
    native_language: '',
    school: '',
    state: '',
    surname: '',
    timezone: '',
  });

  const coachPreferencesListing = useAppSelector(state => {
    return state.registerStudent.coachPreferences;
  });

  const coachTypes = useAppSelector(state => {
    return state.registerStudent.listings.coachType;
  });

  const coachDescriptorsListing = useAppSelector(state => {
    return state.registerStudent.coachPersonalityDescriptors;
  });

  const interestsListing = useAppSelector(state => {
    return state.registerStudent.interests;
  });

  const timezones = useAppSelector(state => {
    return state.registerStudent.listings.timezones;
  });

  const coachingLanguages = useAppSelector(state => {
    return state.registerStudent.listings.coachingLanguages;
  });

  const nativeLanguages = useAppSelector(state => {
    return state.registerStudent.listings.coachingLanguages;
  });

  const modalConfig = {
    interests: {
      placeholder: 'A custom interest',
      subtitle: 'Add all the you did not find in the list.',
      title: 'Add interests',
    },
  };

  const editButton = (
    <div
      className={styles.editButton}
      onClick={() => {
        setIsEditing(true);
      }}
    >
      Edit profile
      <img alt="Edit" className={styles.edit} src={Edit} />
    </div>
  );

  const validateInput = (childData: { [key: string]: string }) => {
    switch (Object.keys(childData)[0]) {
      case 'name':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.name = 'Please enter your name';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.name = '';
            return update;
          });
        }
        break;
      case 'surname':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.surname = 'Please enter your lastname';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.surname = '';
            return update;
          });
        }
        break;
      case 'email':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.email = 'Please enter your email';
            return update;
          });
        } else if (!Object.values(childData)[0].match(regExpressions.email)) {
          setErrors(prevState => {
            const update = { ...prevState };
            update.email = 'Please enter a valid email';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.email = '';
            return update;
          });
        }
        break;
      case 'timezone':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.timezone = 'Please choose a timezone';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.timezone = '';
            return update;
          });
        }
        break;
      case 'coach_type':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.timezone = 'Please choose a type of coach';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.timezone = '';
            return update;
          });
        }
        break;
      case 'native_language':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.native_language = 'Please choose a native language';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.native_language = '';
            return update;
          });
        }
        break;
      case 'coaching_language':
        if (Object.values(childData)[0].length === 0) {
          setErrors(prevState => {
            const update = { ...prevState };
            update.coaching_language = 'Please choose a language for coaching';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.coaching_language = '';
            return update;
          });
        }
        break;
      case 'date_of_birth':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.date_of_birth = 'Please enter your date of birth';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.date_of_birth = '';
            return update;
          });
        }
        break;
      case 'country':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.country = 'Please choose your country';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.country = '';
            return update;
          });
        }
        break;
      case 'state':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.state = 'Please choose your state';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.state = '';
            return update;
          });
        }
        break;
      case 'city':
        if (Object.values(childData)[0] === '') {
          setErrors(prevState => {
            const update = { ...prevState };
            update.city = 'Please enter your city';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.city = '';
            return update;
          });
        }
        break;
      case 'campus_ids':
        if (Object.values(childData)[0]?.length === 0) {
          setErrors(prevState => {
            const update = { ...prevState };
            update.campus_ids = 'Please choose school(s)';
            return update;
          });
        } else {
          setErrors(prevState => {
            const update = { ...prevState };
            update.campus_ids = '';
            return update;
          });
        }
        break;
      default:
        break;
    }
  };

  const updateCoachDb = async (data: any) => {
    try {
      const response = await updateCoach(data);
      if (response !== undefined) {
        localStorage.setItem('user', JSON.stringify(response?.data));
        setUser(response.data);
        setIsEditing(false);
        toast.success('Profile updated successfully');
      }
    } catch (error: any) {
      setUser(user);
      toast.error('There was a problem, please try again');
      setIsEditing(false);
    }
  };

  const handleUpdateUser = async () => {
    const updateData = { ...user };

    if (!updateData?.coaches_campuses && user?.coaches_campuses?.length > 0) {
      updateData.coaches_campuses = user?.coaches_campuses || [];
    } else if (
      !updateData?.coaches_campuses &&
      ((user?.coaches_campuses && user?.coaches_campuses?.length === 0) ||
        !user?.coaches_campuses)
    ) {
      updateData.coaches_campuses = [];
    }
    await updateCoachDb(updateData);
  };

  useEffect(() => {
    if (
      (!!user &&
        (user.biography === '' ||
          user.coaches_campuses?.length === 0 ||
          user.interests?.length === 0 ||
          user.preferences?.length === 0 ||
          user.personality_descriptors?.length === 0)) ||
      errors.name ||
      errors.surname ||
      errors.email ||
      errors.timezone ||
      errors.native_language ||
      errors.coaching_language ||
      errors.date_of_birth ||
      errors.country ||
      errors.state ||
      errors.city ||
      errors.school
    ) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [user, errors]);

  const fullName = user && (
    <div className={styles.nameContainer}>{`${user.name} ${user.surname}`}</div>
  );

  const actionButtons = (
    <div className={styles.actionButtonsContainer}>
      <Button
        isDisabled={isDisabled}
        label="Save changes"
        onClickHandler={() => {
          return handleUpdateUser();
        }}
      />
      <div
        className={styles.discard}
        onClick={() => {
          setUser(user);
          setIsEditing(false);
        }}
      >
        Discard changes
      </div>
    </div>
  );

  const aboutMeLabel = <div className={styles.sectionTitle}>ABOUT ME</div>;

  const email = user && (
    <div>
      <div className={styles.greyTitle}>Email</div>
      <div className={styles.plain}>{user.email}</div>
    </div>
  );

  const timezone = user && (
    <div>
      <div className={styles.greyTitle}>Timezone</div>
      <div className={styles.plain}>{user.timezone}</div>
    </div>
  );

  const coachTypeFiltered =
    user &&
    coachTypes.filter((element: Listing) => {
      return element.value === user.coach_type;
    })[0];

  const coachType = user && (
    <div>
      <div className={styles.greyTitle}>Which best describes you</div>
      <div className={styles.plain}>{coachTypeFiltered?.label}</div>
    </div>
  );

  const capitalizedNativeLanguage =
    user &&
    user.native_language.charAt(0).toUpperCase() +
      user.native_language.slice(1);

  const nativeLanguage = user && (
    <div>
      <div className={styles.greyTitle}>Native Language</div>
      <div className={styles.plain}>{capitalizedNativeLanguage}</div>
    </div>
  );

  const capitalizedLanguage =
    user &&
    user.coaching_language.map((language: string, index: number) => {
      return `${language.charAt(0).toUpperCase()}${language.slice(1)}${
        index === user.coaching_language.length - 1 ? '' : ', '
      }`;
    });

  const language = (
    <div>
      <div className={styles.greyTitle}>Language for coaching</div>
      <div className={styles.plain}>{capitalizedLanguage}</div>
    </div>
  );

  const birthday = user && (
    <div>
      <div className={styles.greyTitle}>Birthday</div>
      <div className={styles.plain}>
        {moment(user.date_of_birth).format('MM/DD/YYYY')}
      </div>
    </div>
  );

  const country = user && (
    <div>
      <div className={styles.greyTitle}>Country</div>
      <div className={styles.plain}>{user.country}</div>
    </div>
  );

  const state = user && (
    <div>
      <div className={styles.greyTitle}>State</div>
      <div className={styles.plain}>{user.state}</div>
    </div>
  );

  const city = user && (
    <div>
      <div className={styles.greyTitle}>City</div>
      <div className={styles.plain}>{user.city}</div>
    </div>
  );

  const school = user && (
    <div>
      <div className={styles.greyTitle}>Schools</div>
      <div className={styles.plain}>
        <Conditional
          condition={user.coaches_campuses?.length > 1}
          Fallback={<Typography text="'No schools entered'" />}
        >
          <div>
            {user.coaches_campuses?.map((element, index: number) => {
              return index !== user.coaching_language.length - 1
                ? `${element.campus_id}, `
                : element.campus_id;
            })}
          </div>
        </Conditional>
      </div>
    </div>
  );

  const selectedUniversities = useMemo(() => {
    if (user?.coaches_campuses?.length && universities?.length) {
      return user.coaches_campuses.map(campus => {
        return universities.find((university: Listing) => {
          // todo: refactor that select component to use ids instead of names
          return university.id === campus.campus_id;
        })?.name;
      });
    }

    return [];
  }, [user?.coaches_campuses, universities]);

  const aboutMeContainer = (
    <div className={styles.aboutMeContainer}>
      {aboutMeLabel}
      {isEditing ? (
        <Input
          className="fullWidth"
          defaultValue={user ? user.name : ''}
          errorMessage={errors.name}
          isInvalid={!!errors.name}
          label="First Name"
          name="name"
          placeholder="Your name"
          type="text"
          onChangeHandler={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        ''
      )}
      {isEditing ? (
        <Input
          className="fullWidth"
          defaultValue={user ? user.surname : ''}
          errorMessage={errors.surname}
          isInvalid={!!errors.surname}
          label="Last Name"
          name="surname"
          placeholder="Your lastname"
          type="text"
          onChangeHandler={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        ''
      )}
      {isEditing ? (
        <Input
          className="fullWidth"
          defaultValue={user ? user.email : ''}
          errorMessage={errors.email}
          isInvalid={!!errors.email}
          isReadOnly
          label="Email"
          name="email"
          placeholder="email@domain.com"
          readOnly
          type="email"
          onChangeHandler={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        email
      )}

      {isEditing ? (
        <SelectInput
          defaultValue={user.coach_type || ''}
          error={errors.coach_type}
          label="Which best describes you"
          name="coach_type"
          options={coachTypes}
          onChange={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        coachType
      )}
      {isEditing ? (
        <SelectInput
          defaultValue={user.timezone || ''}
          error={errors.timezone}
          label="Timezone"
          name="timezone"
          options={timezones}
          onChange={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        timezone
      )}
      {isEditing ? (
        <SelectInput
          defaultValue={user.native_language || ''}
          error={errors.native_language}
          label="Native language"
          name="native_language"
          options={nativeLanguages}
          onChange={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        nativeLanguage
      )}
      {isEditing ? (
        <SelectInput
          defaultValue={user.coaching_language || ''}
          error={errors.coaching_language}
          isMulti
          label="Language for coaching"
          name="coaching_language"
          options={coachingLanguages}
          onChangeMultiple={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        language
      )}
      {isEditing ? (
        <MaskedInput
          className={styles.birthdate}
          data-testid="birthday"
          defaultValue={moment(user.date_of_birth).format('MM/DD/YYYY') || ''}
          error={errors.date_of_birth}
          format="##/##/####"
          label="Birthdate"
          mask="_"
          name="date_of_birth"
          placeholder="Set date (mm/dd/yyyy)"
          onChange={e => {
            handleInputChange(e);
          }}
        >
          <img alt="calendar" className={styles.calendar} src={calendarIcon} />
        </MaskedInput>
      ) : (
        birthday
      )}
      {isEditing ? (
        <SelectInput
          defaultValue={user.country || ''}
          error={errors.country}
          label="Country"
          name="country"
          options={countries}
          onChange={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        country
      )}
      {isEditing ? (
        <SelectInput
          defaultValue={user.state || ''}
          error={errors.state}
          label="State"
          name="state"
          options={states}
          onChange={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        state
      )}
      {isEditing ? (
        <Input
          className="fullWidth"
          defaultValue={user ? user.city : ''}
          errorMessage={errors.city}
          isInvalid={!!errors.city}
          label="City"
          name="city"
          placeholder="Your city"
          type="text"
          onChangeHandler={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        city
      )}
      {isEditing ? (
        <SelectInput
          defaultValue={selectedUniversities}
          error={errors.campus_ids}
          idMap
          isMulti
          label="Choose your school"
          name="campus_ids"
          options={universities || []}
          onChangeMultiple={e => {
            handleInputChange(e);
          }}
        />
      ) : (
        school
      )}
    </div>
  );

  const biographyContainer = (
    <div className={styles.biographyContainer}>
      <div className={styles.card}>
        <div className={styles.sectionTitle}>BIOGRAPHY</div>
        {isEditing ? (
          <Textarea
            defaultValue={user ? user.biography : ''}
            name="biography"
            placeholder="Please add your biography..."
            rows={5}
            onChangeArea={e => {
              return handleInputChange(e);
            }}
          />
        ) : (
          user.biography
        )}
      </div>
    </div>
  );

  const filtredCoachPreferences = coachPreferencesListing.predefined.filter(
    preference => {
      return user.preferences.includes(preference.value);
    },
  );

  const moreAboutMeContainer = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingAboutLabel}>MORE ABOUT ME</div>

      <CoachPreferences
        data={user.preferences.reduce(
          (acc: { [key: string]: boolean }, preference: any) => {
            acc[preference] = true;

            return acc;
          },
          {},
        )}
        isRegister={false}
        listing={coachPreferencesListing}
        onChangeHandler={profileData => {
          return handleDataChange(profileData);
        }}
      />
    </div>
  ) : (
    <div className={styles.moreAboutMeContainer}>
      <div className={styles.card}>
        <div className={styles.cardTitle}>COACH PREFERRENCE</div>
        <div className={styles.plain}>
          {filtredCoachPreferences && filtredCoachPreferences.length > 0
            ? filtredCoachPreferences.map((preference, index) => {
                return `${preference.label}${
                  index < filtredCoachPreferences.length - 1 ? ', ' : ''
                }`;
              })
            : 'No preferences added'}

          {coachPreferencesListing.custom!.length > 0 && <br />}
          {coachPreferencesListing.custom!.length > 0 &&
            coachPreferencesListing.custom?.map((customPreference, index) => {
              return `${customPreference.label}${
                index < coachPreferencesListing.custom!.length - 1 ? ', ' : ''
              }`;
            })}
        </div>
      </div>
    </div>
  );

  const filteredInterests = interestsListing.predefined.filter(interest => {
    return user.interests.includes(interest.value);
  });

  const interestsContainer = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingLabel}>INTERESTS</div>
      {user.interests.length === 0 ? (
        <div className={styles.error}>Please choose at least one interest</div>
      ) : (
        ''
      )}
      <Interests
        data={user.interests.reduce(
          (acc: { [key: string]: boolean }, interest: any) => {
            acc[interest] = true;
            return acc;
          },
          {},
        )}
        listing={interestsListing}
        onChangeHandler={profileData => {
          return handleDataChange(profileData);
        }}
      />
      <AddItemButton
        title="Add other"
        variant="round"
        onClickHandler={() => {
          setModalSelection('interests');
          setIsModalOpen(true);
          dispatch(setOverlay(true));
        }}
      />
    </div>
  ) : (
    <div className={styles.interestsContainer}>
      <div className={styles.card}>
        <div className={styles.cardTitle}>INTERESTS</div>
        <div className={styles.interestsBar}>
          {filteredInterests && filteredInterests.length > 0
            ? filteredInterests.map(interest => {
                return (
                  <InterestCard
                    interest={{
                      label: interest.label,
                      value: interest.value,
                    }}
                    key={interest.label + interest.value}
                  />
                );
              })
            : 'No interests added'}
          {interestsListing.custom!.length > 0 &&
            interestsListing.custom?.map(customInterest => {
              return (
                <InterestCard
                  interest={{
                    label: customInterest.label,
                    value: customInterest.value,
                  }}
                  key={customInterest.label + customInterest.value}
                />
              );
            })}
        </div>
      </div>
    </div>
  );

  const filtredCoachDescriptors = coachDescriptorsListing.predefined.filter(
    descriptor => {
      if (user && user.personality_descriptors) {
        return user.personality_descriptors.includes(descriptor.value);
      }

      return false;
    },
  );

  const personalityContainer = isEditing ? (
    <div className={styles.editingSection}>
      <div className={styles.editingLabel}>PERSONALITY DESCRIPTORS</div>

      {user.personality_descriptors ? (
        <CoachPersonalityDescriptors
          data={user.personality_descriptors.reduce(
            (acc: { [key: string]: boolean }, descriptor: any) => {
              acc[descriptor] = true;
              return acc;
            },
            {},
          )}
          listing={coachDescriptorsListing}
          onChangeHandler={profileData => {
            return handleDataChange(profileData);
          }}
        />
      ) : (
        <CoachPersonalityDescriptors
          data={filtredCoachDescriptors.reduce(
            (acc: { [key: string]: boolean }, descriptor: any) => {
              acc[descriptor] = true;
              return acc;
            },
            {},
          )}
          listing={coachDescriptorsListing}
          onChangeHandler={profileData => {
            return handleDataChange(profileData);
          }}
        />
      )}
    </div>
  ) : (
    <div className={styles.personalityContainer}>
      <div className={styles.card}>
        <div className={styles.cardTitle}>PERSONALITY DESCRIPTORS</div>
        <div className={styles.plain}>
          {user.personality_descriptors &&
          user.personality_descriptors.length > 0
            ? user.personality_descriptors.map(
                (desc: string, index: number) => {
                  return (
                    desc &&
                    `${
                      desc.charAt(0).toUpperCase() +
                      desc.slice(1).replace(/_+/g, ' ')
                    }${
                      index < user.personality_descriptors.length - 1
                        ? ', '
                        : ''
                    }`
                  );
                },
              )
            : 'No personality descriptors added'}
        </div>
      </div>
    </div>
  );

  const cardsContainer = (
    <div className={styles.cardsContainer}>
      {biographyContainer}
      {moreAboutMeContainer}
      {personalityContainer}
      {interestsContainer}
    </div>
  );

  const saveCustomEntries = (customEntriesData: any) => {
    const index = Object.keys(customEntriesData)[0];
    const values = Object.values(customEntriesData[index]) as Listing[];

    // values = values.filter((value) => !data.topics.includes(value.label));
    switch (index) {
      case 'interests':
        dispatch(addCustomInterest(values));
        setUser((prevState: any) => {
          const update = { ...prevState };
          update.interests.push(
            ...values.map(a => {
              return a.value;
            }),
          );
          return update;
        });
        break;
    }
  };

  const removeCustomEntry = (customEntry: any) => {
    const index = Object.keys(customEntry)[0];
    switch (index) {
      case 'interests':
        setUser((prevState: any) => {
          const update = { ...prevState };
          update.interests = update.interests.filter((el: any) => {
            return el !== Object.values(customEntry)[0];
          });
          return update;
        });
        interestsListing.custom = interestsListing.custom!.filter(element => {
          return element.value !== Object.values(customEntry)[0];
        });
        break;
    }
  };

  const handleDataChange = (childData: any) => {
    const index = Object.keys(childData)[0];
    const values = Object.keys(childData[index]);
    setUser((prevState: any) => {
      const update = { ...prevState };
      update[index] = values;
      return update;
    });
  };

  const handleInputChange = async (inputData: any) => {
    validateInput(inputData);
    const index = Object.keys(inputData)[0];
    setUser((prevState: any) => {
      const update = { ...prevState };
      update[index] = Object.values(inputData)[0];
      return update;
    });
  };

  return (
    <UserLayout centeredTitle title="My Profile">
      <div className={styles.container}>
        <div className={styles.avatarSection}>
          <Avatar image={user.profile_img} name={user.name} />
          {!isEditing && fullName}
          {!isEditing && editButton}
        </div>
        {aboutMeContainer}
        {cardsContainer}
        {isEditing && actionButtons}
      </div>
      {isModalOpen &&
        addModalToPortal(
          <Modal
            currentStepKey={modalSelection}
            handleClose={() => {
              setIsModalOpen(false);
              dispatch(setOverlay(false));
            }}
            handleSave={saveCustomEntries}
            listing={interestsListing}
            placeholder={
              modalConfig[modalSelection as keyof typeof modalConfig]
                .placeholder
            }
            removeCustomEntry={removeCustomEntry}
            subtitle={
              modalConfig[modalSelection as keyof typeof modalConfig].subtitle
            }
            title={
              modalConfig[modalSelection as keyof typeof modalConfig].title
            }
          />,
        )}
    </UserLayout>
  );
};

export default CoachProfileOverview;
