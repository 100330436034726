import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import FullCalendar from '@fullcalendar/react';
import { AllowFunc, DateSelectArg, EventInput } from '@fullcalendar/core';
import { v4 as uuidV4 } from 'uuid';
import { useFetchCoachStudents } from '../../features/coach/api/useFetchCoachStudents';
import StudentCard from '../../features/coach/components/StudentCard/StudentCard';
import UserLayout from '../../features/general/components/user-layout/user-layout';
import Button from '../../features/register-coachee/components/ui/Button/Button';
import { Student } from '../../features/register-coachee/interfaces/Student';
import {
  sessionsNumberMapped,
  steps,
} from '../../utils/constants/CreateNewSession';
import { localStorageUser } from '../../utils/helpers/localStorageUser';
import { useCoachTimeslots } from '../../features/coach/api/useCoachTimeslots';
import SelectInput from '../../features/register-coachee/components/ui/SelectInput/SelectInput';
import { addToDate } from '../../utils/helpers/date';
import { CalendarWrapper, Conditional, Typography } from '../../common';
import { createSession } from '../../features/coach/api/createSession';
import EmptyModal from '../../features/general/components/empty-modal/empty-modal';

import styles from './create-session.module.scss';

const CreateSession = () => {
  const [events, setEvents] = useState<EventInput[]>([]);
  const [user] = useState(localStorageUser());
  const navigate = useNavigate();
  const data = useFetchCoachStudents();
  const {
    capacityExceededModalVisible,
    isLoading,
    onDatesSet,
    onTimeslotSelected,
    setCapacityExceededApproved,
    setCapacityExceededModalVisible,
    timeslotsData,
  } = useCoachTimeslots({
    coachId: user.id,
  });
  const [sessionsNumber, setSessionsNumber] = useState<number>(1);
  const [step, setStep] = useState(steps.chooseStudent);
  const [selectedStudent, setSelectedStudent] = useState(null);

  useEffect(() => {
    if (!isLoading) {
      setEvents(
        timeslotsData?.timeslots?.map(timeslot => {
          return {
            display: 'background',
            end: new Date(+timeslot.end_time),
            groupId: 'available',
            id: timeslot.id,
            start: new Date(+timeslot.start_time),
          } as EventInput;
        }),
      );
    }
  }, [isLoading]);

  const handleCreateSession = async () => {
    try {
      const coachTimeslot = events.find(event => {
        return event.extendedProps?.lastAdded;
      });

      await createSession({
        coachId: localStorageUser().id,
        numberOfSessions: sessionsNumber,
        studentId: selectedStudent!.id as string,
        timeslotId: coachTimeslot.extendedProps.timeslotId,
      });

      toast.success('Session successfully created!');

      navigate('/coach/dashboard');
    } catch {
      toast.error("Couldn't create session");
    }
  };
  const handleSelect = async (arg: DateSelectArg) => {
    const availableTimeslot = events.find(event => {
      return arg.start < event.end && arg.end > event.start;
    });

    const newEvent: EventInput = {
      constraint: 'available',
      durationEditable: false,
      editable: true,
      end: arg.end,
      extendedProps: {
        lastAdded: true,
        timeslotId: availableTimeslot?.id,
      },
      id: uuidV4(),
      start: arg.start,
      title: 'Available',
    };

    const updatedEvents = [
      ...events.filter(event => {
        return event.groupId === 'available';
      }),
      newEvent,
    ];

    setEvents(updatedEvents);

    onTimeslotSelected();
  };

  const chooseStudent = !data.isLoading
    ? data.studentsData.map((student: Student, index: number) => {
        return (
          <div className={styles.container} key={index}>
            <StudentCard
              key={student.name + student.surname}
              selected={selectedStudent?.id === student.id}
              student={student}
              onSelect={(student, isSelected) => {
                setSelectedStudent(
                  student.id === selectedStudent?.id && !isSelected
                    ? null
                    : student,
                );
              }}
            />
          </div>
        );
      })
    : '';

  const selectAllow = (selectInfo: DateSelectArg) => {
    const { end, start } = selectInfo;

    return events.some(event => {
      return start < event.end && end > event.start;
    });
  };

  const selectedEvent = useMemo(() => {
    return events?.find(event => {
      return event.groupId !== 'available';
    });
  }, [events]);

  const handleConfirm = () => {
    setCapacityExceededModalVisible(false);
    setCapacityExceededApproved(true);
  };

  const hideModal = () => {
    setCapacityExceededModalVisible(false);
    setEvents(
      events.filter(event => {
        return !event.extendedProps?.lastAdded;
      }),
    );
  };

  return (
    <Conditional
      condition={step === steps.chooseStudent}
      Fallback={
        <UserLayout
          centeredTitle
          customBackAction={() => {
            return setStep(steps.chooseStudent);
          }}
          title="Available timeslots"
        >
          <Fragment>
            <SelectInput
              defaultValue="1"
              label="Number of sessions"
              name="number_of_sessions"
              options={sessionsNumberMapped}
              onChange={(event: any) => {
                setSessionsNumber(parseInt(event.number_of_sessions));
              }}
            />

            <CalendarWrapper>
              <FullCalendar
                allDaySlot={false}
                businessHours={false}
                datesSet={onDatesSet}
                displayEventTime={false}
                editable
                eventDurationEditable={false}
                events={events}
                height="calc(100vh - 300px)"
                initialView="timeGridWeek"
                plugins={[timeGridPlugin, dayGridPlugin, interactionPlugin]}
                scrollTimeReset={false}
                select={handleSelect}
                selectable
                selectAllow={selectAllow as unknown as AllowFunc}
                selectOverlap
                unselectAuto={false}
                validRange={{ start: addToDate(new Date(), 36, 'hours') }}
              />
            </CalendarWrapper>

            <div className={styles.actionButton}>
              <Conditional
                condition={step === steps.chooseStudent}
                Fallback={
                  <Button
                    isDisabled={!selectedEvent}
                    label="Create session"
                    onClickHandler={handleCreateSession}
                  />
                }
              >
                <Button
                  isDisabled={selectedStudent === null}
                  label="Choose student"
                  onClickHandler={() => {
                    return setStep(steps.chooseTimeslot);
                  }}
                />
              </Conditional>
            </div>
          </Fragment>

          <Conditional condition={capacityExceededModalVisible}>
            <EmptyModal cssClass="height50" handleClose={hideModal}>
              <div className={styles.modalContainer}>
                <Typography
                  text={
                    timeslotsData?.remaining_weekly_capacity <= 0
                      ? 'Weekly capacity exceeded'
                      : 'Weekly capacity for new students exceeded'
                  }
                  variant="subtitle1"
                />

                <Typography
                  text={`You have exceeded your ${
                    timeslotsData?.remaining_weekly_capacity <= 0
                      ? 'capacity'
                      : 'capacity for new students'
                  } this week. Are you sure you want to continue?`}
                />

                <div className={styles.actions}>
                  <Button
                    isDisabled={false}
                    label="Yes, I am sure"
                    onClickHandler={handleConfirm}
                  />

                  <Typography
                    paddingTop="x"
                    text="Cancel"
                    textStyle="bold"
                    onClick={hideModal}
                  />
                </div>
              </div>
            </EmptyModal>
          </Conditional>
        </UserLayout>
      }
    >
      <UserLayout centeredTitle title="Choose a student">
        <div className={styles.container}>{chooseStudent}</div>
        <div className={styles.actionButton}>
          <Conditional
            condition={step === steps.chooseStudent}
            Fallback={
              <Button
                isDisabled={!selectedEvent}
                label="Create session"
                onClickHandler={handleCreateSession}
              />
            }
          >
            <Button
              isDisabled={selectedStudent === null}
              label="Choose student"
              onClickHandler={() => {
                return setStep(steps.chooseTimeslot);
              }}
            />
          </Conditional>
        </div>
      </UserLayout>
    </Conditional>
  );
};

export default CreateSession;
