import React, { Fragment, useEffect } from 'react';
import { Toaster } from 'react-hot-toast';
import { useLocation } from 'react-router-dom';
import { setOverlay } from './features/general/store/generalSlice';
import { useConfigFetch } from './features/register-coachee/api/useConfigFetch';
import { setConfig } from './features/register-coachee/store/registerStudentSlice';
import MainRoutes from './routes/Routes';
import { useAppDispatch, useAppSelector } from './store/hooks';

import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';

Bugsnag.start({
  apiKey: '34c49e0f1b7534f3bce1fa980645c9ca',
  plugins: [new BugsnagPluginReact()],
});

const ErrorBoundary = Bugsnag.getPlugin('react')?.createErrorBoundary(React);

function TrulaCampusApp() {
  const config = useConfigFetch();
  const dispatch = useAppDispatch();
  const overlay = useAppSelector(state => {
    return state.general.overlay;
  });
  const location = useLocation();
  useEffect(() => {
    if (config) {
      dispatch(setConfig(config));
    }
    if (overlay) {
      document.getElementById('root')?.classList.add('fixed');
      document.getElementById('overlayElement')?.classList.add('overlay');
    } else {
      document.getElementById('root')?.classList.remove('fixed');
      document.getElementById('overlayElement')?.classList.remove('overlay');
    }
  }, [config, overlay, dispatch]);

  //* Disable the overlay if going back/forward
  useEffect(() => {
    if (overlay) {
      dispatch(setOverlay(false));
    }
  }, [location]);

  const Container = ErrorBoundary ? ErrorBoundary : Fragment;

  return (
    <Container>
      <Toaster />
      <MainRoutes />
    </Container>
  );
}

export default TrulaCampusApp;
