import { Fragment, useEffect } from 'react';
import Avatar from '../../../general/components/avatar/avatar';
import { CoachMatch } from '../../interfaces/CoachMatch';
import InterestCard from '../interest-card/interest-card';

import styles from './user-carousel-card.module.scss';

type Props = {
  coach: CoachMatch;
};

const UserCarouselCard = ({ coach }: Props) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [coach]);

  //* Renders
  const avatarRender = (
    <img
      alt="profile pic"
      className={styles.avatar}
      src="https://www.kindpng.com/picc/m/22-223941_transparent-avatar-png-male-avatar-icon-transparent-png.png"
    />
  );

  const nameRender = (
    <div className={styles.name}>
      {`${coach.name} ${
        coach.surname ? coach.surname?.charAt(0)?.toUpperCase() : ''
      }.`}
    </div>
  );

  const percentageRender = (
    <div className={styles.matchPercentage}>
      ({coach.match_percentage}% match)
    </div>
  );

  const languageRender = (
    <div className={styles.pb16}>
      <div className={styles.lightLabel}>Language for coaching</div>
      <div className={styles.plain}>
        <Fragment>
          {coach.coaching_language.map((language, index) => {
            return `${language.charAt(0).toUpperCase()}${language.slice(1)}${
              index === coach.coaching_language.length - 1 ? '' : ', '
            }`;
          })}
        </Fragment>
      </div>
    </div>
  );

  const nativeLanguageRender = (
    <div className={styles.pb16}>
      <div className={styles.lightLabel}>Native language</div>
      <div className={styles.plain}>{coach.native_language}</div>
    </div>
  );

  const biographyRender = (
    <div className={`${styles.module} ${styles.pb24}`}>
      <div className={`${styles.darkLabel} ${styles.pb8}`}>BIOGRAPHY</div>
      <div className={styles.plain}>{coach.biography}</div>
    </div>
  );

  const preferencesRender = (
    <div className={`${styles.module} ${styles.pb24}`}>
      <div className={`${styles.darkLabel} ${styles.pb16}`}>MORE ABOUT ME</div>
      <div className={styles.listing}>
        <Fragment>
          {coach.filteredPreferences?.map(preference => {
            return (
              <InterestCard interest={preference} key={preference.label} />
            );
          })}
        </Fragment>
      </div>
    </div>
  );

  const personalityDescriptorRender = (
    <div className={`${styles.module} ${styles.pb24}`}>
      <div className={`${styles.darkLabel} ${styles.pb16}`}>
        PERSONALITY DESCRIPTORS
      </div>
      <div className={styles.listing}>
        <Fragment>
          {' '}
          {coach.filteredPersonalityDescriptors?.map(interest => {
            return <InterestCard interest={interest} key={interest.label} />;
          })}
        </Fragment>
      </div>
    </div>
  );

  const interestsRender = (
    <div className={`${styles.module} ${styles.pb24}`}>
      <div className={`${styles.darkLabel} ${styles.pb16}`}>INTERESTS</div>
      <div className={styles.listing}>
        <Fragment>
          {coach.filteredInterests?.map(interest => {
            return <InterestCard interest={interest} key={interest.label} />;
          })}
        </Fragment>
      </div>
    </div>
  );

  return (
    <div className={styles.container}>
      <Avatar image={coach.profile_img} name={coach.name} size="medium" />
      {nameRender}
      {coach.match_percentage >= 40 ? percentageRender : ''}

      <div className={styles.module}>
        <div className={`${styles.darkLabel} ${styles.pb16}`}>
          {'about me'.toUpperCase()}
        </div>
        {languageRender}

        {nativeLanguageRender}
      </div>
      {coach.biography !== null ? (
        <Fragment>
          <div className={styles.separator} />
          {biographyRender}
        </Fragment>
      ) : (
        ''
      )}

      <div className={styles.separator} />
      {preferencesRender}
      {coach.filteredPersonalityDescriptors &&
      coach.filteredPersonalityDescriptors.length > 0 ? (
        <Fragment>
          <div className={styles.separator} />
          {personalityDescriptorRender}
        </Fragment>
      ) : (
        ''
      )}

      <div className={styles.separator} />
      {interestsRender}
    </div>
  );
};

export default UserCarouselCard;
