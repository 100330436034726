import random from 'lodash/random';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import { Button, Flex, Typography } from '../../common';

import styles from './welcome.module.scss';

export const Welcome = () => {
  return (
    <div className={cx(styles.containerWrapper, styles[`bg-${random(1, 5)}`])}>
      <div className={styles.container}>
        <div className={styles.contentContainer}>
          <div className={styles.content}>
            <div className={styles.logo} />

            <Flex direction="column" gap="3x" width="100%">
              <Flex direction="column" gap="x" width="100%">
                <Typography
                  color="white"
                  text="Welcome to Trula"
                  variant="title"
                />
                <Typography
                  color="white"
                  text="Amet minim mollit non deserunt ullamco est sit aliqua dolor do amet sint."
                />
              </Flex>

              <Flex direction="column" gap="2x" width="100%">
                <Link to="/login">
                  <Button className={styles.loginBtn} text="Log in" />
                </Link>

                <Link to="/coachee/register">
                  <Button className={styles.createBtn} text="Create account" />
                </Link>
              </Flex>
            </Flex>
          </div>
        </div>
      </div>
    </div>
  );
};
