import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import useAuth from '../../hooks/UseAuth';
import { adminSignIn, coacheeSignIn, coachSignIn } from '../../api/userSignIn';
import { LogIn } from '../../utils/constants/LogIn';
import { STATUSES } from '../../../register-coachee/utils/constants/Statuses';
import Button from '../../../register-coachee/components/ui/Button/Button';
import Password from '../../../register-coachee/components/ui/Password/Password';
import { IPasswordData } from '../../../../utils/interfaces/RegisterStudent';
import { Conditional, Flex, Layout, Typography } from '../../../../common';

import styles from './LoginUser.module.scss';

const LoginUser = () => {
  const { cognitoUserSignIn, cognitoUserSignOut } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const redirectUrl = query.get('redirectUrl');
  const [user, setUser] = useState({
    password: '',
    username: '',
  });
  const [forceChangePassword, setForceChangePassword] = useState(false);
  const [cognitoUser, setCognitoUser] = useState({});
  const [passwordData, setPasswordData] = useState<IPasswordData>();
  const [cognitoGroup, setCognitoGroup] = useState<string>('');
  const handlePasswordChange = (data: IPasswordData) => {
    setPasswordData(data);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleCoachLogin = async () => {
    try {
      const response = await coachSignIn();

      if (response && response.data) {
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate(redirectUrl || '/coach/dashboard');
      } else if (response && response.code === 'ERR_BAD_REQUEST') {
        await cognitoUserSignOut();
        if (response?.response?.data?.message?.message) {
          toast.error(response.response.data.message.message);
        } else {
          toast.error('Something went wrong, please try again!');
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while signing in.');
    }
  };

  const handleAdminLogin = async () => {
    try {
      const response = await adminSignIn();

      if (response && response.data) {
        localStorage.setItem('user', JSON.stringify(response.data));
        navigate('/admin');
      } else if (response && response.code === 'ERR_BAD_REQUEST') {
        await cognitoUserSignOut();
        if (response?.response?.data?.message?.message) {
          toast.error(response.response.data.message.message);
        } else {
          toast.error('Something went wrong, please try again!');
        }
      }
    } catch (error) {
      console.error(error);
      toast.error('Error while signing in.');
    }
  };

  const handleCoacheeLogin = () => {
    coacheeSignIn().then(response => {
      if (response && response.data) {
        localStorage.setItem('user', JSON.stringify(response.data));

        switch (response.data.status) {
          case STATUSES.ACTIVE:
            navigate(redirectUrl || '/coachee/dashboard');
            break;
          case STATUSES.ACTIVE_NOT_VERIFIED:
            navigate('/coachee/profile');
            break;
          case STATUSES.REGISTER_CHOOSE_YOUR_COACH:
            navigate('/coachee/coach-matching');
            break;
          case STATUSES.CHANGE_COACH:
            navigate('/coachee/coach-matching');
            break;
          default:
            navigate('/coachee/register');
            break;
        }
      } else if (response && response.code === 'ERR_BAD_REQUEST') {
        cognitoUserSignOut();
        if (response?.response?.data?.message?.message) {
          return toast.error(response.response.data.message.message);
        }
        return toast.error('Something went wrong, please try again!');
      }
    });
  };

  useEffect(() => {
    if (cognitoGroup !== '') {
      localStorage.setItem('cognitoGroup', cognitoGroup);
    }
  }, [cognitoGroup]);

  const handleLogIn = async () => {
    try {
      const response = await cognitoUserSignIn(user);

      if (localStorage.getItem('cognito_id')) {
        const data = await Auth.currentAuthenticatedUser();
        const userGroup = data.signInUserSession?.accessToken?.payload[
          'cognito:groups'
        ]
          ? data.signInUserSession.accessToken.payload['cognito:groups'][0]
          : 'coachee';

        setCognitoGroup(userGroup);

        if (data.signInUserSession?.accessToken?.payload['cognito:groups']) {
          if (userGroup === 'admin') {
            handleAdminLogin();
          } else {
            handleCoachLogin();
          }
        } else {
          handleCoacheeLogin();
        }
      } else if (response.challengeName === 'NEW_PASSWORD_REQUIRED') {
        setCognitoUser(response);
        setForceChangePassword(true);
      }
    } catch (error: unknown) {
      toast.error('Incorrect username or password.');
    }
  };

  const handlePostLogin = (response: any) => {
    localStorage.setItem('user', JSON.stringify(response.data));
    Auth.currentAuthenticatedUser().then(data => {
      if (data.signInUserSession.accessToken.payload['cognito:groups']) {
        localStorage.setItem(
          'cognitoGroup',
          data.signInUserSession.accessToken.payload['cognito:groups'][0],
        );
        navigate('/coach/dashboard');
      }
    });
  };

  const handleChangePassword = async () => {
    try {
      const user = await Auth.completeNewPassword(
        cognitoUser,
        passwordData!.data.password,
      );

      localStorage.setItem(
        'cognito_id',
        user.signInUserSession.accessToken.payload.sub,
      );

      let signInResponse;
      if (cognitoGroup === 'admin') {
        signInResponse = await adminSignIn();
      } else {
        signInResponse = await coachSignIn();
      }

      handlePostLogin(signInResponse);
    } catch (error) {
      console.error(error);
    }
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    keyName: string,
  ) => {
    event.persist();
    setUser(user => {
      return { ...user, [keyName]: event.target.value };
    });
  };

  return (
    <Layout
      Footer={
        <Button
          isDisabled={
            (forceChangePassword &&
              (!passwordData?.isMatch ||
                passwordData?.isInvalid ||
                passwordData.data.password !==
                  passwordData.data.repeatPassword)) ||
            (!forceChangePassword &&
              (user.username === '' || user.password === ''))
          }
          label={forceChangePassword ? LogIn.CHANGE_PASSWORD : LogIn.LABEL}
          onClickHandler={
            !forceChangePassword ? handleLogIn : handleChangePassword
          }
        />
      }
      hasBackButton
      subtitle={
        forceChangePassword ? null : (
          <Flex alignItems="center" gap=".5x">
            <Typography color="secondary" text={LogIn.DONT_HAVE_ACCOUNT} />{' '}
            <Link to="/coachee/register">
              <Typography
                color="info"
                decoration="underline"
                text={LogIn.CREATE_ONE_LINK}
                textStyle="bold"
                variant="subtitle2"
              />
            </Link>
          </Flex>
        )
      }
      title="Log in to Trula"
      onBackButtonClick={() => {
        return navigate('/');
      }}
    >
      <form>
        <Conditional
          condition={forceChangePassword}
          Fallback={
            <Flex direction="column" gap="x">
              <div className={styles.inputContainer}>
                <div className={styles.labelContainer}>
                  <label htmlFor={LogIn.EMAIL}>{LogIn.EMAIL}</label>
                </div>
                <input
                  className={styles.input}
                  name="email"
                  placeholder={LogIn.EMAIL_PLACEHOLDER}
                  tabIndex={1}
                  type="text"
                  value={user.username}
                  onChange={e => {
                    return handleInputChange(e, 'username');
                  }}
                />
              </div>

              <div className={styles.inputContainer}>
                <div className={styles.labelContainer}>
                  <label htmlFor={LogIn.PASSWORD}>{LogIn.PASSWORD}</label>
                </div>
                <input
                  className={styles.input}
                  name="password"
                  placeholder={LogIn.PASSWORD_PLACEHOLDER}
                  tabIndex={2}
                  type="password"
                  value={user.password}
                  onChange={e => {
                    return handleInputChange(e, 'password');
                  }}
                />
              </div>
            </Flex>
          }
        >
          <p className={styles.subtitle}>
            Since this is your first time logging in, please change your
            temporary password
          </p>

          <Password
            errorMessage="Passwords must match"
            label="Password"
            placeholder="Set your password"
            repeatLabel="Repeat Password"
            repeatPlaceholder="Repeat Password"
            onChangePasswordHandler={handlePasswordChange}
          />
        </Conditional>

        <Conditional condition={!forceChangePassword}>
          <Link replace to="/reset-password">
            <Typography
              align="right"
              color="info"
              decoration="underline"
              text={LogIn.FORGOTTEN_PASSWORD}
              variant="subtitle3"
            />
          </Link>
        </Conditional>
      </form>
    </Layout>
  );
};
export default LoginUser;
