import { FC, useEffect } from 'react';
import timeGridPlugin from '@fullcalendar/timegrid';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { EventClickArg, EventInput } from '@fullcalendar/core';
import FullCalendar from '@fullcalendar/react';
import { useNavigate } from 'react-router-dom';
import { useImmer } from 'use-immer';
import { addMinutes } from '../../../../utils/helpers/date';
// @ts-ignore
import { Session } from '../../interfaces/session';
import { CalendarWrapper, Conditional } from '../../../../common';
import EmptyListing from '../../../general/components/empty-listing/empty-listing';
import emptySessions from '../../../../assets/icons/EmptyListing/emptySessions.svg';
import { renderEventContent } from '../../../../helpers/full-calendar';

import styles from './history-sessions.module.scss';

type Props = {
  coachName: string;
  historySessions: Session[];
};

const HistorySessions: FC<Props> = props => {
  const { coachName, historySessions } = props;
  const navigate = useNavigate();

  const [events, updateEvents] = useImmer<EventInput[]>([]);

  useEffect(() => {
    if (historySessions?.length) {
      updateEvents(
        historySessions?.map((session: Session) => {
          const start = new Date(+session.session_date);
          return {
            backgroundColor: '#ec6359',
            borderColor: '#ec6359',
            display: 'auto',
            end: addMinutes(start, 30),
            extendedProps: session,
            id: session.id,
            start,
            title: coachName,
          } as EventInput;
        }),
      );
    }
  }, [historySessions]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={styles.container}>
      <Conditional
        condition={!!events?.length}
        Fallback={
          <EmptyListing
            image={emptySessions}
            subtitle="History sessions will appear here"
            title="No sessions yet"
          />
        }
      >
        <CalendarWrapper>
          <FullCalendar
            allDaySlot={false}
            businessHours={false}
            dayHeaderFormat={{ day: 'numeric', weekday: 'short' }}
            eventClick={(arg: EventClickArg) => {
              navigate(`/coachee/session/${arg.event.id}`);
            }}
            eventContent={renderEventContent}
            eventDurationEditable={false}
            events={events}
            headerToolbar={{
              center: 'title',
              left: 'prev,next',
              right: 'timeGridWeek,listWeek',
            }}
            height="calc(100vh - 200px)"
            initialView="listWeek"
            noEventsText="No sessions this week."
            plugins={[
              timeGridPlugin,
              dayGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            scrollTimeReset={false}
            selectable
            selectOverlap
            unselectAuto={false}
            validRange={{ end: new Date() }}
            views={{
              listWeek: { buttonText: 'List', displayEventTime: true },
              timeGridWeek: {
                buttonText: 'Calendar',
                displayEventTime: false,
              },
            }}
          />
        </CalendarWrapper>
      </Conditional>
    </div>
  );
};

export default HistorySessions;
